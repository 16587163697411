<template>
  <create-card>
    <template v-slot:header>
      <!-- content for the header slot -->
      <h2 class="card-header">Profile Details</h2>
      <!-- <p class="card-header-company-name">Trek Dieries</p> -->
    </template>

    <p class="section-note">This profile will be visible to your customers</p>
    <div class="input_wrap">
      <h4 class="sm-label mb-10">Image Gallery</h4>

      <div
        class="file_input_dumy"
        :class="{ '--cover': coverPhoto }"
        @click="openUppyCover = true"
      >
        <img class="file_input_icon" src="@/assets/icons/image.svg" alt="" />
        <span>Upload Photos for Image Gallery</span>
        <!-- <uppy-photo
          v-if="coverPhoto"
          :imgName="coverPhoto"
          :key="coverPhoto"
          class="--cover-img"
          alt=""
        ></uppy-photo> -->
      </div>
      <UppyImgInput
        v-show="openUppyCover"
        @fileUploaded="fileUploadedCover"
        @close="openUppyCover = false"
        :filesLimit="10"
      />
    </div>
    <h4 class="input-note --warning">
      Cover photo will also be used for your website main background
    </h4>
    <PhotosList
      v-if="photos && photos.length"
      :photos="photos"
      :cover="coverPhoto"
      @photoDeleted="deletePhoto"
      @setCover="setCover"
    ></PhotosList>

    <div class="input_wrap --logo-input-wrap">
      <div class="file_input_dumy --logo" @click="openUppyLogo = true">
        <img
          class="file_input_icon"
          v-if="!logoPhoto"
          src="@/assets/icons/image.svg"
          alt=""
        />
        <uppy-photo
          v-if="logoPhoto"
          :imgName="logoPhoto"
          :key="logoPhoto"
          class="--logo-img"
          alt=""
        />
      </div>
      Company Logo
      <UppyImgInput
        v-show="openUppyLogo"
        @fileUploaded="fileUploadedLogo"
        @close="openUppyLogo = false"
        :filesLimit="1"
      />
    </div>

    <div class="input_wrap my-15">
      <label for="smInput" class="sm-label">Company Tagline</label>
      <input
        v-model="tagline"
        class="--name-input sm_input my-10"
        type="text"
        placeholder="Write your company tagline..."
        autocomplete="off"
      />
    </div>

    <div class="input_wrap my-15">
      <label for="smInput" class="sm-label">Company Description</label>
      <textarea
        v-model="description"
        class="--name-input sm_input my-10"
        type="text"
        placeholder="Describe your company here..."
        autocomplete="off"
        rows="5"
      ></textarea>
    </div>

    <h4>Company History</h4>
    <p class="section-note">This info will gain customers trust</p>
    <div class="input_wrap my-20">
      <label for="smInput" class="sm-label"
        >Year of establishment (Approximate Date)</label
      >
      <date-picker
        class="my-10"
        @datesSelected="selectDates"
        :selectedDates="establishmentDate"
      ></date-picker>
    </div>
    <div class="input_wrap my-15">
      <label for="smInput" class="sm-label">No. of accomplished treks</label>
      <input
        v-model="eventsCompleted"
        class="--name-input sm_input my-10"
        type="string"
        placeholder="Enter no. of treks organized till date"
        autocomplete="off"
      />
    </div>
    <div class="input_wrap my-15">
      <label for="smInput" class="sm-label">No. of customers</label>
      <input
        v-model="noOfCustomers"
        class="--name-input sm_input my-10"
        type="string"
        placeholder="Enter No. of customers you served till date"
        autocomplete="off"
      />
    </div>

    <div class="cta-wrap">
      <div class="pill_btn --hollow --left-icon" @click="back()">
        <img class="back-btn-icon" src="@/assets/icons/angle-left.svg" alt="" />
        Back
      </div>
      <div class="pill_btn --right-icon" @click="updateProfile()">
        Save & Finish <img src="@/assets/icons/angle-right.svg" alt="" />
      </div>
    </div>
    <p
      class="text-center my-15"
      style="cursor: pointer"
      @click="
        $router.push({
          name: 'CompanyDashboard',
          params: {
            companyId: company?._id,
          },
        })
      "
    >
      <u>Skip for now</u>
    </p>
  </create-card>
</template>

<script>
import CreateCard from "@/components/layouts/CreateCard.vue";
import UppyImgInput from "@/components/inputs/UppyInput.vue";
import UppyPhoto from "@/components/common/UppyPhoto.vue";
import PhotosList from "@/components/common/PhotosList.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import GetterServices from "@/services/company/GetterServices";
import CompanySetters from "@/services/company/SetterServices";
import computedMixin from "@/mixins/computedMixin";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  mixins: [computedMixin],
  components: { CreateCard, UppyImgInput, UppyPhoto, PhotosList, DatePicker },
  data() {
    return {
      loadingUpdate: false,
      openUppyCover: false,
      openUppyLogo: false,
      photos: [],
      coverPhoto: null,
      logoPhoto: null,
      tagline: null,
      description: null,
      establishmentDate: [],
      eventsCompleted: null,
      noOfCustomers: null,
    };
  },
  computed: {
    companyId() {
      return this.$route.params.companyId;
    },
  },
  mounted() {
    if (this.companyId) {
      this.prefill();
    }
  },
  methods: {
    back() {
      this.$router.push({
        name: "CreateLocations",
        params: { companyId: this.company._id },
      });
    },
    async prefill() {
      let company = await GetterServices.companyById(this.companyId);
      this.company = company.data;
      // console.log(this.company);
      if (this.company.photos) {
        this.photos = this.company.photos;
      }
      if (this.company.cover_pic) {
        this.coverPhoto = this.company.cover_pic;
      }
      if (this.company.logo) {
        this.logoPhoto = this.company.logo;
      }
      if (this.company.company_tagline) {
        this.tagline = this.company.company_tagline;
      }
      if (this.company.description) {
        this.description = this.company.description;
      }
      if (this.company.establishment_date) {
        this.establishmentDate = this.getDateWithoutTime(
          this.company.establishment_date
        );
      }
      if (this.company.events_completed) {
        this.eventsCompleted = this.company.events_completed;
      }
      if (this.company.no_of_customers) {
        this.noOfCustomers = this.company.no_of_customers;
      }
    },
    selectDates(e) {
      if (e) {
        this.establishmentDate = e;
      }
    },

    getDateWithoutTime(dateTimeString) {
      const date = new Date(dateTimeString);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    deletePhoto(photo) {
      const index = this.photos.indexOf(photo);
      this.photos.splice(index, 1);
      if (this.coverPhoto == photo) {
        this.coverPhoto = this.photos[0] || "";
      }
    },
    fileUploadedCover(e) {
      setTimeout(() => {
        if (!this.coverPhoto) {
          this.coverPhoto = e.name;
        }
        this.photos.push(e.name);
        // console.log(this.photos);
      }, 1000);
    },
    setCover(cover) {
      this.coverPhoto = cover;
    },
    fileUploadedLogo(e) {
      setTimeout(() => {
        this.logoPhoto = e.name;
      }, 1000);
    },

    async updateProfile() {
      try {
        this.loadingUpdate = true;
        const data = {
          photos: this.photos,
          coverPhoto: this.coverPhoto,
          logo: this.logoPhoto,
          tagline: this.tagline,
          description: this.description,
          establishmentDate: this.establishmentDate,
          eventsCompleted: this.eventsCompleted,
          noOfCustomers: this.noOfCustomers,
        };
        console.log(data);
        const company = await CompanySetters.updateCompanySection(
          this.companyId,
          data,
          "profile"
        );
        if (company?._id) {
          this.$router.push({
            name: "CompanyDashboard",
            params: { companyId: company._id },
          });
        }
      } catch (error) {
        toast.error("Something went wrong, please try again!", {
          autoClose: 3000,
          hideProgressBar: true,
        });
        this.loadingUpdate = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.--name-input {
  font-size: $txt-sm;
  padding: $sp-md;
}
.card-header-company-name {
  margin-bottom: 36px;
}

.card-header,
.card-header-company-name {
  text-align: left;
}
.--logo-input-wrap {
  display: flex;
  align-items: center;
  gap: $sp-md;
}
.cta-wrap {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media only screen and (min-width: 600px) {
  .--name-input {
    font-size: $txt-md;
    padding: $sp-md;
  }
}
</style>
