<template>
  <div class="filter-wrap">
    <h2 class="filter-drawer-heading">
      Filters
      <img
        src="@/assets/icons/cross-small.svg"
        alt=""
        class="close-filter-icon"
        @click="$emit('closeDrawer')"
      />
    </h2>

    <div class="input_wrap my-20">
      <div class="my-10">Event Date</div>
      <date-picker
        @datesSelected="selectDates"
        :selectedDates="date"
        :dark="true"
      ></date-picker>
    </div>
    <div class="input_wrap my-15">
      <div class="my-10">Ticket</div>
      <div
        class="dropdown_dumy --dark"
        :class="{
          '--opened': showTicketDropdown,
          '--selected': selectedTicket.length,
        }"
        @click.stop="showTicketDropdown = !showTicketDropdown"
      >
        {{ selectedTicket[0]?.label || "Select Ticket filter" }}
        <span class="close_icon" v-show="showTicketDropdown">x</span>
        <img
          v-show="!showTicketDropdown"
          src="@/assets/icons/angle-small-down.svg"
          alt=""
          class="close_icon"
        />
      </div>
      <DropDown
        class="--dark"
        v-if="showTicketDropdown"
        :options="TicketOptions"
        :selectedOps="selectedTicket"
        @selected="ticketSelected"
        :key="TicketOptions"
        :singleSelect="true"
        :searchBox="true"
      ></DropDown>
    </div>

    <div class="input_wrap my-15" v-if="selectedTicket?.length">
      <div class="my-10">Pickup Point</div>
      <div
        class="dropdown_dumy --dark"
        :class="{
          '--opened': showPickupPointDropdown,
          '--selected': selectedPickupPoint.length,
        }"
        @click.stop="showPickupPointDropdown = !showPickupPointDropdown"
      >
        {{ selectedPickupPoint[0]?.label || "Select pickup point filter" }}
        <span class="close_icon" v-show="showPickupPointDropdown">x</span>
        <img
          v-show="!showPickupPointDropdown"
          src="@/assets/icons/angle-small-down.svg"
          alt=""
          class="close_icon"
        />
      </div>
      <DropDown
        class="--dark"
        v-if="showPickupPointDropdown"
        :options="PickupPointOptions"
        :selectedOps="selectedPickupPoint"
        @selected="pickupPointSelected"
        :key="PickupPointOptions"
        :singleSelect="true"
        :searchBox="true"
      ></DropDown>
    </div>

    <div class="actions-btns">
      <div class="pill_btn --small" @click="applyFilters">Apply filters</div>
      <div class="pill_btn --hollow --small" @click="clearFilters">
        Clear filters
      </div>
    </div>
  </div>
</template>

<script>
import DropDown from "@/components/inputs/DropDown.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
export default {
  props: ["tickets", "prifilledFilters"],
  components: {
    DropDown,
    DatePicker,
  },
  data() {
    return {
      selectedDate: null,
      selectedTicket: [],
      showTicketDropdown: false,
      TicketOptions: [],
      selectedPickupPoint: [],
      showPickupPointDropdown: false,
      PickupPointOptions: [],
    };
  },
  watch: {
    selectedTicket: {
      handler() {
        this.PickupPointOptions = [];
        this.selectedTicket[0]?.pickup_points.forEach((pp) => {
          const p = {
            label: pp.address.full_address,
            id: pp._id,
          };
          this.PickupPointOptions.push(p);
        });
        // console.log(this.PickupPointOptions);
      },
      deep: true,
    },
    prifilledFilters: {
      handler() {
        if (!this.prifilledFilters?.filterTicket) {
          this.selectedTicket = [];
        }
        if (!this.prifilledFilters?.filterPickup) {
          this.selectedPickupPoint = [];
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.tickets?.forEach((ticket) => {
      const t = {
        label: ticket.ticket_heading,
        id: ticket._id,
        pickup_points: ticket.pickup_points,
      };
      this.TicketOptions.push(t);
    });
    if (this.prifilledFilters?.filterTicket) {
      this.selectedTicket = [this.prifilledFilters?.filterTicket];
    }
    if (this.prifilledFilters?.filterPickup) {
      this.selectedPickupPoint = [this.prifilledFilters?.filterPickup];
    }
  },
  methods: {
    selectDates(e) {
      if (e) {
        this.selectedDate = e;
      } else {
        this.selectedDate = null;
      }
    },
    ticketSelected(option) {
      this.selectedTicket = option;
      this.showTicketDropdown = false;
      this.selectedPickupPoint = [];
    },
    pickupPointSelected(option) {
      this.selectedPickupPoint = option;
      this.showPickupPointDropdown = false;
    },
    applyFilters() {
      let filters = {};
      if (this.selectedTicket[0]) {
        filters.filterTicket = this.selectedTicket[0];
      }
      if (this.selectedPickupPoint[0]) {
        filters.filterPickup = this.selectedPickupPoint[0];
      }
      if (this.selectedDate) {
        filters.filterDate = this.selectedDate;
      }
      this.$emit("filtersSelected", filters);
    },
    clearFilters() {
      this.selectedTicket = [];
      this.selectedPickupPoint = [];
      this.$emit("filtersSelected", null);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.filter-wrap {
  padding: $sp-md;

  .filter-drawer-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .close-filter-icon {
      width: $icon-w-md;
      cursor: pointer;
    }
  }
}
.actions-btns {
  display: flex;
  align-items: center;
  gap: $sp-md;
}
</style>
