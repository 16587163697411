<template>
  <img
    class="uppy-photo"
    v-if="imgUrl && !loadingImage"
    :src="imgUrl"
    :key="imgUrl"
    alt=""
  />
</template>

<script>
import axios from "axios";
import computedMixin from "@/mixins/computedMixin";
import constants from "@/constants";

export default {
  mixins: [computedMixin],

  props: ["imgName"],
  data() {
    return {
      imgUrl: null,
      loadingImage: true,
    };
  },
  computed: {
    currentPlace() {
      if (this.currentCityId) {
        return this.currentCity;
      } else if (this.currentSpotId) {
        return this.currentSpot;
      } else if (this.currentTTDId) {
        return this.currentTTD;
      } else {
        return 0;
      }
    },
  },
  mounted() {
    this.getPhotos();
  },
  methods: {
    isImageUrlValid(url) {
      return new Promise((resolve) => {
        const img = new Image();

        img.onload = function () {
          // Image loaded successfully
          resolve(true);
        };

        img.onerror = function () {
          // Error loading image
          resolve(false);
        };

        img.src = url;
      });
    },
    async getPhotos() {
      this.loadingImage = true;
      // main function
      await axios
        .get(`${constants.apiUrl}/images/get?imgName=${this.imgName}`)
        .then((res) => {
          // console.log(await this.isImageUrlValid(), "validate");
          this.imgUrl = res.data.url;
          this.loadingImage = false;
        })
        .catch((err) => {
          console.log(err, "erro");
        });
      // main function
    },
  },
};
</script>

<style lang="scss" scoped>
.uppy-photo {
  width: 100%;
  object-fit: cover;
}
</style>
