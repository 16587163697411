<template>
  <div class="treks-view">
    <div class="treks-view__wrap">
      <div class="my-treks">
        <!-- treks list -->
        <div class="treks-list">
          <!-- <div class="create-trek-card" @click="initCreateTrek()">
                  <img src="@/assets/icons/add.svg" alt="" class="create-trek-icon" />
                  <h4>Create Trek</h4>
                </div> -->
          <div
            class="trek-card --no-treks"
            v-if="!loadingTreks && !treks.length"
          >
            No Events to Copy
          </div>
          <div
            v-show="loadingTreks"
            class="shimmers"
            v-for="s in loadingTreks * 6"
            :key="s"
          ></div>
          <!-- treks card -->
          <div class="trek-card" v-for="trek in treks" :key="trek._id">
            <!-- trek cover -->
            <uppy-photo
              v-if="trek.cover_pic"
              :imgName="trek.cover_pic"
              alt=""
              class="trek-cover-pic"
            />
            <img
              v-else
              src="@/assets/imgs/dummy-trek/trek-cover.jpg"
              alt=""
              class="trek-cover-pic"
            />

            <!-- trek details -->
            <div class="trek-details">
              <div class="trek-details-info">
                <div class="trek-name">{{ trek.trek_name }}</div>
                <!-- <div class="trek-date" v-if="trek.event_date">
                  {{ convertDate(trek.event_date) }}
                </div> -->
              </div>
              <div
                class="pill_btn --small copy-btn"
                :class="{ '--disabled': loadingCopy }"
                @click="copyEvent(trek._id)"
              >
                <span v-if="loadingCopy !== trek._id">Copy</span>
                <loader-spinner v-if="loadingCopy == trek._id"></loader-spinner>
              </div>
            </div>
          </div>
          <!-- treks card -->
        </div>
        <!-- treks list -->
      </div>
    </div>
  </div>
</template>

<script>
import TrekGetters from "@/services/trek/GetterService";
import TrekSetters from "@/services/trek/SetterServices";
import UppyPhoto from "@/components/common/UppyPhoto.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import computedMixin from "@/mixins/computedMixin";
import LoaderSpinner from "@/components/common/LoaderSpinner.vue";
export default {
  mixins: [computedMixin],
  components: {
    UppyPhoto,
    LoaderSpinner,
  },
  data() {
    return {
      loadingTreks: false,
      loadingCopy: null,
      treks: [],
    };
  },
  async mounted() {
    if (this.company) {
      await this.getTreks();
    }
  },
  methods: {
    async getTreks() {
      try {
        this.loadingTreks = true;
        const data = await TrekGetters.getRecentTrekTemplates(
          this.company?._id
        );
        this.treks = data.data.treks;
        this.loadingTreks = false;
      } catch (error) {
        this.loadingTreks = false;
        toast.error("Failed to get events. Please try to reload!", {
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    },
    convertDate(d) {
      const date = new Date(d); // Replace this with your desired date

      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };

      const formattedDate = date.toLocaleDateString("en-US", options);
      return formattedDate;
    },
    async copyEvent(trekId) {
      try {
        this.loadingCopy = trekId;
        this.$emit("copingEvent");
        const trek = await TrekSetters.copyTrek(this.company._id, trekId);
        this.$router.push({
          name: "CreateTrekInfo",
          params: { companyId: this.company._id },
          query: { trekId: trek._id },
        });
        this.loadingCopy = null;
      } catch (error) {
        toast.error("Failed to copy event. Please try again!", {
          autoClose: 3000,
          hideProgressBar: true,
        });
        this.loadingCopy = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.treks-view {
  display: flex;
  justify-content: center;

  .treks-view__wrap {
    width: 100%;
    box-sizing: border-box;

    .my-treks {
      margin-top: $sp-lg;
      padding-bottom: 60px;

      .treks-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: $sp-md;

        .create-trek-card {
          min-height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: $sp-md;
          background-color: $secondary-bg-color;
          border-radius: $br-sm;
          cursor: pointer;
          &:hover {
            background-color: $secondary-bg-color-hover;
            // transform: scale(0.98);
          }
        }

        .shimmers {
          height: 150px; /* Set the desired height of your card */
          border-radius: $br-sm;
          overflow: hidden;
          background-color: $secondary-bg-color-hover;

          /* Apply the shimmer effect */
          position: relative;

          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(
              90deg,
              transparent,
              #070a123b,
              transparent
            );
            animation: shimmer 2s infinite;
          }

          @keyframes shimmer {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(100%);
            }
          }
        }
        .trek-card {
          border-radius: $br-sm;
          overflow: hidden;
          box-sizing: border-box;
          height: 150px;
          background-color: $secondary-bg-color;
          border: 1px solid $tertiary-text-color;
          cursor: pointer;
          position: relative;

          &:hover {
            background-color: $secondary-bg-color-hover;

            .trek-details {
              background-image: linear-gradient(to top, #070a12e7, #070a12b0);
            }
          }

          &.--no-treks {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border: 1px dashed $secondary-text-color;
          }

          .trek-cover-pic {
            width: 100%;
            height: 150px;
            object-fit: cover;
          }
          .trek-details {
            padding: $sp-md $sp-sm;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: $sp-xs;
            position: absolute;
            bottom: $sp-xxs;
            left: $sp-xxs;
            width: calc(100% - $sp-xxs - $sp-xxs);
            box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
            backdrop-filter: blur(7.5px);
            -webkit-backdrop-filter: blur(7.5px);
            border: 1px solid rgba(255, 255, 255, 0.18);
            background-image: linear-gradient(45deg, #070a12ba, #070a1249);
            border-radius: $br-sm;
            .trek-logo {
              height: 36px;
              width: 36px;
              border-radius: 100px;
            }
            .trek-details-info {
              display: flex;
              flex-direction: column;
              justify-content: center;
              width: 70%;
              .trek-name {
                font-size: $txt-md;
                width: 100%;
                overflow: hidden;
                white-space: wrap;
                text-overflow: ellipsis;
              }
              .trek-date {
                margin-top: $sp-xs;
                font-size: $txt-lg;
                color: $primary-text-color;

                .trek-date-item {
                  margin-right: $sp-xs;
                  display: flex;
                  gap: $sp-xs;
                }
              }
            }
            .copy-btn {
              box-shadow: 0 0 5px $shadow-color-md;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
</style>
