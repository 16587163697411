<template>
  <section>
    <h4 v-if="!noHeader">New Location</h4>
    <form autocomplete="off" class="input_wrap my-10" v-if="!onlyFullAddress">
      <p for="smInput" class="sm-label mb-8">Country</p>
      <p class="selected-option" v-if="countrySelected && countryName">
        <span>{{ countryName }}</span>
        <img
          class="selected-option__cross"
          @click="selectCountry()"
          src="@/assets/icons/cross-small.svg"
          alt=""
        />
      </p>
      <input
        v-else
        v-model="countryName"
        class="sm_input my-5"
        type="text"
        placeholder="Search country"
        autocomplete="off"
        @keyup="countryInputChanged()"
      />
      <div class="options" v-if="loader === 'country'">
        <loader-spinner></loader-spinner>
      </div>
      <div
        class="options"
        v-if="countries && countries.length && countryName && !countrySelected"
      >
        <div
          v-for="country in countries"
          :key="country.wikiDataId"
          class="option"
          @click="selectCountry(country)"
        >
          {{ country.name }}
        </div>
      </div>
    </form>

    <form autocomplete="off" class="input_wrap my-10" v-if="!onlyFullAddress">
      <p for="smInput" class="sm-label mb-8">State</p>
      <p class="selected-option" v-if="stateSelected && stateName">
        <span>{{ stateName }}</span>
        <img
          class="selected-option__cross"
          @click="selectState()"
          src="@/assets/icons/cross-small.svg"
          alt=""
        />
      </p>
      <input
        v-else
        v-model="stateName"
        class="sm_input"
        type="text"
        placeholder="Search state"
        autocomplete="off"
        :disabled="!countryName"
        @keyup="stateInputChanged()"
      />
      <div class="input-note --error my-10" v-if="!countrySelected">
        Select country first
      </div>
      <div class="options" v-if="loader === 'state'">
        <loader-spinner></loader-spinner>
      </div>
      <div
        class="options"
        v-if="states && states.length && stateName && !stateSelected"
      >
        <div
          v-for="state in states"
          :key="state.wikiDataId"
          class="option"
          @click="selectState(state)"
        >
          {{ state.name }}
        </div>
      </div>
    </form>

    <form autocomplete="off" class="input_wrap my-10" v-if="!onlyFullAddress">
      <p for="smInput" class="sm-label mb-8">City</p>
      <p class="selected-option" v-if="citySelected && cityName">
        <span>{{ cityName }}</span>
        <img
          class="selected-option__cross"
          @click="selectCity()"
          src="@/assets/icons/cross-small.svg"
          alt=""
        />
      </p>
      <input
        v-else
        v-model="cityName"
        class="sm_input"
        type="text"
        placeholder="Search city"
        autocomplete="off"
        :disabled="!stateName"
        @keyup="cityInputChanged()"
      />
      <div class="input-note --error my-10" v-if="!stateSelected">
        Select state first
      </div>
      <div class="options" v-if="loader === 'city'">
        <loader-spinner></loader-spinner>
      </div>
      <div
        class="options"
        v-if="cities && cities.length && cityName && !citySelected"
      >
        <div
          v-for="city in cities"
          :key="city.wikiDataId"
          class="option"
          @click="selectCity(city)"
        >
          {{ city.name }}
        </div>
      </div>
    </form>

    <div class="input_wrap" v-if="includeFullAddress || onlyFullAddress">
      <label for="smInput" class="sm-label">Address</label>

      <input
        v-model="full_address"
        class="sm_input my-10"
        type="text"
        placeholder="Enter exact address"
        autocomplete="off"
      />
    </div>
    <div class="input_wrap" v-if="includeTimePicker">
      <label for="smInput" class="sm-label">Select Pickup Date & Time</label>
      <div class="split_inputs date-time-split my-10">
        <date-picker
          @datesSelected="selectDates"
          :selectedDates="date"
        ></date-picker>
        <time-picker
          @timeSelected="selectTime"
          :selectedTime="time"
        ></time-picker>
        <!-- <input v-model="time" type="time" /> -->
      </div>
    </div>

    <div class="cta-wrap" v-if="!noBtn">
      <div class="pill_btn --hollow" @click="$emit('cancel')">Cancel</div>
      <div
        class="pill_btn"
        :class="{
          '--disabled':
            (!onlyFullAddress && (!countryName || !stateName || !cityName)) ||
            (!onlyFullAddress && !full_address) ||
            (includeTimePicker && (!date || !time)),
        }"
        @click="addLocation()"
      >
        Add this location
      </div>
    </div>
  </section>
</template>

<script>
import getAddress from "@/mixins/getAddress";
import TimePicker from "@/components/inputs/TimePicker.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import LoaderSpinner from "@/components/common/LoaderSpinner.vue";
export default {
  mixins: [getAddress],
  components: {
    TimePicker,
    DatePicker,
    LoaderSpinner,
  },
  props: [
    "prefillLocation",
    "noHeader",
    "noBtn",
    "includeFullAddress",
    "onlyFullAddress",
    "includeTimePicker",
  ],
  data() {
    return {
      loading: false,
      country: {},
      countryName: null,
      countrySelected: false,
      countries: [],
      state: {},
      stateName: null,
      stateSelected: false,
      states: [],
      city: {},
      cityName: null,
      citySelected: false,
      cities: [],
      full_address: "",
      date: null,
      time: null,
      debounceTimer: null,
      loader: null,
    };
  },
  watch: {
    currentCity() {
      this.initialFill();
    },

    citySelected() {
      if (this.citySelected && this.cityName && this.noBtn) {
        this.addLocation();
      }
    },
    prefillLocation: {
      handler() {
        this.prefill();
      },
      deep: true,
    },
  },
  mounted() {
    let country = {
      code: "IN",
      currencyCodes: ["INR"],
      name: "India",
      wikiDataId: "Q668",
    };
    this.selectCountry(country);
    this.prefill();
  },
  methods: {
    prefill() {
      if (this.prefillLocation) {
        this.full_address =
          this.full_address || this.prefillLocation.address.full_address;
        this.countryName =
          this.countryName || this.prefillLocation.address.country;
        this.stateName = this.stateName || this.prefillLocation.address.state;
        this.cityName = this.cityName || this.prefillLocation.address.city;
        this.date =
          this.date || new Date(this.prefillLocation.datetime).toDateString();
        this.time = this.time || {
          hours: new Date(this.prefillLocation.datetime).getHours(),
          minutes: new Date(this.prefillLocation.datetime).getMinutes(),
          seconds: new Date(this.prefillLocation.datetime).getSeconds(),
        };
        if (this.countryName) this.countrySelected = true;
        if (this.stateName) this.stateSelected = true;
        if (this.cityName) this.citySelected = true;
      }
    },
    async cityInputChanged() {
      if (!this.citySelected && this.cityName?.length) {
        this.loader = "city";
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(async () => {
          await this.getCities();
          this.loader = null;
        }, 400); // Adjust the time (in milliseconds) based on how long you want to wait after the user stops typing.
      }
    },

    async stateInputChanged() {
      if (!this.stateSelected && this.stateName?.length) {
        this.loader = "state";
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(async () => {
          await this.getStates();
          this.cityName = "";
          this.city = {};
          this.loader = null;
        }, 500); // Adjust the time (in milliseconds) based on how long you want to wait after the user stops typing.
      }
    },

    async countryInputChanged() {
      if (!this.countrySelected && this.countryName?.length) {
        this.loader = "country";
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(async () => {
          await this.getCountries();
          this.stateName = "";
          this.state = {};
          this.cityName = "";
          this.city = {};
          this.loader = null;
        }, 500); // Adjust the time (in milliseconds) based on how long you want to wait after the user stops typing.
      }
    },
    selectCountry(country) {
      if (country) {
        this.country = country;
        this.countryName = country.name;
        this.countrySelected = true;
        this.countries = [];
      } else {
        this.country = {};
        this.countryName = "";
        this.countrySelected = false;
        this.countries = [];
        this.state = {};
        this.stateName = "";
        this.stateSelected = false;
        this.states = [];
        this.city = {};
        this.cityName = "";
        this.citySelected = false;
        this.cities = [];
      }
    },

    selectState(state) {
      // console.log(state);
      if (state) {
        this.state = state;
        this.stateName = state.name;
        this.stateSelected = true;
        this.states = [];
      } else {
        if (this.prefillLocation) {
          this.country = {};
          this.countryName = "";
          this.countrySelected = false;
          this.countries = [];
        }
        this.state = {};
        this.stateName = "";
        this.stateSelected = false;
        this.states = [];
        this.city = {};
        this.cityName = "";
        this.citySelected = false;
        this.cities = [];
      }
    },

    selectCity(city) {
      if (city) {
        this.city = city;
        this.cityName = city.name;
        this.citySelected = true;
        this.cities = [];
      } else {
        if (this.prefillLocation) {
          this.country = {};
          this.countryName = "";
          this.countrySelected = false;
          this.countries = [];
          this.state = {};
          this.stateName = "";
          this.stateSelected = false;
          this.states = [];
        }
        this.city = {};
        this.cityName = "";
        this.citySelected = false;
        this.cities = [];
      }
    },
    selectDates(e) {
      if (e) {
        this.date = e;
      } else {
        this.date = null;
      }
    },
    selectTime(e) {
      if (e) {
        this.time = e;
      } else {
        this.time = null;
      }
    },
    combineDateTime(dateString, timeObject) {
      const date = new Date(dateString);

      // Set hours, minutes, and seconds from the timeObject
      date.setHours(timeObject.hours);
      date.setMinutes(timeObject.minutes);
      date.setSeconds(timeObject.seconds);

      return date;
    },
    addLocation() {
      let location = {
        country: this.countryName,
        state: this.stateName,
        city: this.cityName,
        full_address: this.full_address,
      };
      if (this.includeTimePicker) {
        const datetime = this.combineDateTime(this.date, this.time);
        this.$emit("locationAdded", { address: location, datetime });
      } else {
        this.$emit("locationAdded", { address: location });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.selected-option {
  text-transform: capitalize;
  padding: 8px;
  font-size: $txt-xs;
  border-radius: 5px;
  background-color: #252936;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: $sp-xs 0;

  .selected-option__cross {
    width: 16px;
    cursor: pointer;
  }
}
.cta-wrap {
  margin-top: $sp-xl;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: $sp-md;
  width: 100%;

  .pill_btn {
    font-size: $txt-xs;
  }
}
</style>
