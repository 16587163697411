<template>
  <home-view>
    <template v-slot:sidebar>
      <!-- <h3 class="sidebar-title" v-if="user">
        {{ user.full_name || user.email }}
      </h3> -->
      <div class="sidebar-items">
        <div
          class="sidebar-item"
          @click="changeSection('my-companies')"
          :class="{ '--active-section': activeSection == 'my-companies' }"
        >
          <img
            class="sidebar-item-icon"
            src="@/assets/icons/companies.svg"
            alt=""
          />
          My Companies
        </div>
        <!-- <div
          class="sidebar-item"
          @click="changeSection('settings'); $router.push({name: 'CreateNewCompany', query: {companyId: }})"
          :class="{ '--active-section': activeSection == 'settings' }"
        >
          <img
            class="sidebar-item-icon"
            src="@/assets/icons/settings.svg"
            alt=""
          />Settings
        </div> -->
      </div>
      <div
        class="--logout"
        @click="
          changeSection('logout');
          logout();
        "
        :class="{ '--active-section': activeSection == 'logout' }"
      >
        <img
          class="sidebar-item-icon"
          src="@/assets/icons/sign-out.svg"
          alt=""
        />
        Log out
      </div>
    </template>
    <template v-slot:content>
      <div class="my-companies">
        <h2 class="my-companies-title">My companies</h2>

        <!-- companies list -->
        <div class="companies-list">
          <div
            class="companies-card --no-companies"
            v-if="!loadingCompanies && !companies.length"
          >
            No Companies Yet!
          </div>
          <div
            v-show="loadingCompanies"
            class="shimmers"
            v-for="s in loadingCompanies * 6"
            :key="s"
          ></div>
          <!-- Companies card -->
          <div
            class="companies-card"
            v-for="company in companies"
            :key="company._id"
            @click="openCompanyDashboard(company)"
          >
            <!-- company cover -->
            <uppy-photo
              v-if="company.cover_pic"
              :imgName="company.cover_pic"
              alt=""
              class="company-cover-pic"
            />
            <img
              v-else
              src="../../assets/imgs/dummy-trek/trek-banner.png"
              alt=""
              class="company-cover-pic"
            />

            <!-- company details -->
            <div class="company-details">
              <uppy-photo
                v-if="company.logo"
                :imgName="company.logo"
                alt=""
                class="company-logo"
              />
              <img
                v-else
                src="../../assets/imgs/dummy-trek/trek-logo.png"
                alt=""
                class="company-logo"
              />

              <div class="company-details-info">
                <div class="company-name">{{ company.company_name }}</div>
                <div class="company-username">
                  {{ company?.company_username }}
                </div>
              </div>
            </div>
            <!-- company details -->
          </div>
          <!-- Companies card -->
        </div>
        <!-- Companies list -->
        <div class="content-add-btn-wrap">
          <div
            class="content-add-btn"
            @click="$router.push({ name: 'CreateNewCompany' })"
          >
            <img
              src="@/assets/icons/add.svg"
              alt=""
              class="add-btn-circle-icon"
            />
            Create Company
          </div>
        </div>
      </div>
    </template>
  </home-view>
</template>

<script>
// import companyGetApisMixin from "@/mixins/company/get/companyGetApisMixin";
import UppyPhoto from "@/components/common/UppyPhoto.vue";
import { getAuth } from "firebase/auth";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import computedMixin from "@/mixins/computedMixin";
import UserSetters from "@/services/user/SetterService";
import CompanyGetters from "@/services/company/GetterServices";
import HomeView from "@/components/layouts/HomeView.vue";
export default {
  mixins: [computedMixin],
  components: { UppyPhoto, HomeView },
  data() {
    return {
      loadingCompanies: false,
      companies: [],
      activeSection: "my-companies",
    };
  },
  async mounted() {
    await this.getUser();
    await this.getCompanies();
  },
  methods: {
    async getUser() {
      try {
        this.loadingCompanies = true;
        let user = await UserSetters.authUser();
        if (user && user._id) {
          this.user = user;
        }
        this.loadingCompanies = false;
      } catch (error) {
        toast.error("Something went wrong, please loging in again!", {
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    },
    async getCompanies() {
      try {
        this.loadingCompanies = true;
        const companies = await CompanyGetters.userCompanies();
        if (companies) {
          this.companies = companies;
        }
        this.loadingCompanies = false;
      } catch (error) {
        toast.error("Something went wrong, please loging in again!", {
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    },

    changeSection(sectionName) {
      this.activeSection = sectionName;
    },
    // handleAuth(data, err) {
    //   if (!err) {
    //     this.user = data;
    //   } else {
    //     console.log(data);
    //   }
    // },
    openCompanyDashboard(company) {
      if (company?.company_username) {
        // let localCompany = {
        //   _id: company._id,
        //   company_name: company.company_name,
        //   company_username: company.company_username,
        // };
        // window.localStorage.setItem("company", JSON.parse(localCompany));
        this.$router.push({
          name: "CompanyDashboard",
          params: { companyId: company._id },
        });
      }
    },
    logout() {
      const auth = getAuth();
      auth.signOut().then(
        () => {
          this.$router.push("/auth/login");
        },
        function (error) {
          toast.error(error, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.sidebar-title {
  padding: $sp-md $sp-lg;
  box-sizing: border-box;
  border-bottom: 1px solid $primary-bg-color;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: $txt-sm;
}
.sidebar-items {
  margin-top: $sp-sm;
  display: flex;
  flex-direction: column;
  position: relative;
  // gap: $sp-sm;

  .sidebar-item {
    padding: $sp-lg $sp-lg;
    box-sizing: border-box;
    font-size: $txt-sm;
    display: flex;
    align-items: center;
    gap: $sp-sm;
    color: $secondary-text-color;
    cursor: pointer;

    .sidebar-item-icon {
      width: $icon-w-xs;
      opacity: 0.6;
    }
    &:hover {
      background-color: $secondary-bg-color-hover;
    }

    &.--active-section {
      background-color: $secondary-bg-color;
      color: $primary-text-color;

      .sidebar-item-icon {
        opacity: 1;
      }
    }
  }
}

.shimmers {
  height: 150px; /* Set the desired height of your card */
  border-radius: $br-sm;
  overflow: hidden;
  background-color: $secondary-bg-color-hover;

  /* Apply the shimmer effect */
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, transparent, #070a123b, transparent);
    animation: shimmer 2s infinite;
  }

  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}
.--logout {
  padding: $sp-lg $sp-lg;
  box-sizing: border-box;
  font-size: $txt-sm;
  display: flex;
  align-items: center;
  gap: $sp-sm;
  color: $primary-text-color;
  border-radius: $br-sm;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  width: 100%;

  .sidebar-item-icon {
    width: $icon-w-xs;
  }
  &:hover {
    background-color: $error-bg-color-hover;
  }

  &.--active-section {
    background-color: $error-bg-color;
    color: $primary-text-color;

    .sidebar-item-icon {
      opacity: 1;
    }
  }
}
// _____________________________MY companies _________________
.my-companies {
  padding-bottom: $sp-xxl;
  padding: 0 $sp-md $sp-lg;
  box-sizing: border-box;
  // height: 100vh;
  // overflow: auto;
  // margin-left: 216px;
  width: calc(100%);
  position: relative;

  .my-companies-title {
    padding: $sp-md;
    margin: 0 0 $sp-lg;
    border-radius: $br-sm;
    background-color: $secondary-bg-color;
    font-size: $txt-md;
  }

  .companies-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: $sp-md;

    .create-company-card {
      min-height: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: $sp-md;
      background-color: $secondary-bg-color;
      border-radius: $br-sm;
      cursor: pointer;

      &:hover {
        background-color: $secondary-bg-color-hover;
        // transform: scale(0.98);
      }
    }

    .companies-card {
      border-radius: $br-sm;
      overflow: hidden;
      box-sizing: border-box;
      background-color: $secondary-bg-color;
      min-height: 160px;
      cursor: pointer;

      &:hover {
        background-color: $secondary-bg-color-hover;
        // transform: scale(0.98);
      }

      &.--no-companies {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 1px dashed $secondary-text-color;
      }

      .company-cover-pic {
        width: 100%;
        height: 110px;
        object-fit: cover;
      }

      .company-details {
        padding: 0 $sp-sm $sp-sm;
        display: flex;
        align-items: center;
        gap: $sp-md;

        .company-logo {
          height: 36px;
          width: 36px;
          border-radius: 100px;
        }

        .company-details-info {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .company-name {
            font-size: $txt-md;
          }

          .company-username {
            font-size: $txt-sm;
            color: $secondary-text-color;

            .company-username-item {
              margin-right: $sp-xs;
              display: flex;
              gap: $sp-xs;
            }
          }
        }
      }
    }
  }
}

.content-add-btn-wrap {
  position: fixed;
  bottom: $sp-md;
  right: $sp-md;
  display: flex;
  justify-content: flex-end;
  padding: $sp-md 0;

  .content-add-btn {
    width: fit-content;
    padding: $sp-sm;
    padding-right: $sp-md;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $sp-sm;
    background-color: $theme-bg-color;
    box-shadow: 0 0 25px $shadow-color-xl;
    font-size: $txt-sm;
    cursor: pointer;

    &:hover {
      background-color: $theme-bg-color-hover;
    }

    .add-btn-circle-icon {
      width: $icon-w-md;
    }
  }
}

// _____________________________MY companies _________________

@media only screen and (min-width: 600px) {
  .sidebar-title {
    border-color: $secondary-bg-color;
  }
  .my-companies {
    padding: 0 0 $sp-lg;
    .companies-list {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

      .create-company-card {
        min-height: 160px;
      }

      .companies-card {
        // flex-basis: calc(50% - $sp-md);
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  .content-add-btn-wrap {
    bottom: $sp-lg;
    right: $sp-lg;

    .content-add-btn {
      padding: $sp-md;
      padding-right: $sp-lg;

      .add-btn-circle-icon {
        width: $icon-w-md;
      }
    }
  }
}
</style>
