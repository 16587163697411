<template>
  <div class="auth_page">
    <div class="auth_cover">
      <div class="gradient_fade"></div>
      <img
        class="auth_img"
        src="@/assets/imgs/auth-view.png"
        alt="travel treking tour mountain"
      />
    </div>
    <div class="auth_card_wrap">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.auth_page {
  background-color: #070a12;
  height: 100vh;
  .auth_cover {
    width: 50%;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    .gradient_fade {
      height: 100vh;
      width: 40%;
      background-image: linear-gradient(to right, #070a12, #070a1200);
      position: absolute;
      left: 0;
      top: 0;
    }
    .auth_img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
  }
  .auth_card_wrap {
    padding-top: 2em;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 50vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 860px) {
  .auth_page {
    .auth_cover {
      width: 100%;
      .gradient_fade {
        width: 180%;
      }
    }
    .auth_card_wrap {
      width: 100vw;
      align-items: flex-start;
      padding-top: 3em;
    }
  }
}
</style>
