<template>
  <section>
    <!-- <slot name="navbar"></slot> -->
    <nav-bar-user @toggleMenu="toggleMenu"></nav-bar-user>
    <div class="home-view">
      <div class="home-inner">
        <div
          class="sidebar-wrap"
          :class="{
            '--open': openSidebar === true,
            '--close': openSidebar === false,
          }"
          @click="openSidebar = false"
        >
          <div
            class="sidebar-content"
            :class="{
              '--open': openSidebar === true,
              '--close': openSidebar === false,
            }"
          >
            <div class="sidebar-items" :class="{ '--open': openSidebar }">
              <slot name="sidebar"></slot>
            </div>
          </div>
        </div>
        <div class="content-wrap">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import NavBarUser from "@/components/common/NavBarUser.vue";
export default {
  components: { NavBarUser },
  data() {
    return {
      openSidebar: null,
    };
  },
  methods: {
    toggleMenu() {
      this.openSidebar = !this.openSidebar;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

@keyframes openSidebarWrap {
  from {
    left: -100vw;
  }
  to {
    left: 0;
  }
}
@keyframes closeSidebarWrap {
  from {
    left: 0;
  }
  to {
    left: -100vw;
    display: none;
  }
}
@keyframes openSidebar {
  from {
    left: -100vw;
  }
  to {
    left: 0;
  }
}
@keyframes closeSidebar {
  from {
    left: 0;
  }
  to {
    left: -100vw;
    display: none;
  }
}
.home-view {
  width: 100%;
  height: 100vh;
  // background-color: #000000;
  // padding: 0 $sp-md;
  box-sizing: border-box;
  .home-inner {
    width: 100%;
    // overflow: hidden;
    height: 100vh;
    margin: 0 auto;
    display: flex;
    padding-top: $sp-nav-mob;
    box-sizing: border-box;
    gap: $sp-md;
    position: relative;
    .sidebar-wrap {
      position: fixed;
      left: 0;
      // padding: $sp-lg 0;
      box-sizing: border-box;
      width: 100%;
      height: calc(100vh - $sp-nav-mob);
      background-color: #25293610;
      backdrop-filter: blur(2px);
      z-index: 100;
      display: flex;
      left: -100vw;
      &.--close {
        animation: closeSidebarWrap 0.25s forwards;
      }
      &.--open {
        animation: openSidebarWrap 0.25s forwards;
      }
      .sidebar-content {
        position: relative;
        background-color: $secondary-bg-color;
        box-shadow: 10px 0 10px $shadow-color-md;
        padding: $sp-md 0;
        box-sizing: border-box;
        width: 200px;
        height: 100%;
        overflow: hidden;
        left: -100vw;

        &.--close {
          animation: closeSidebar 0.25s forwards;
        }
        &.--open {
          animation: openSidebar 0.25s forwards;
        }

        .sidebar-items {
          display: none;
          &.--open {
            display: block;
          }
        }
      }
    }
    .content-wrap {
      width: calc(100%);
      padding-top: $sp-lg;
      box-sizing: border-box;
      //height: fit-content;
      height: calc(100vh - $sp-nav-mob);
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .content {
        height: 120vh;
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .home-view {
    padding: 0 $sp-md;
    .home-inner {
      width: 100%;
      display: flex;
      padding-top: $sp-nav-des;

      .sidebar-wrap {
        position: initial;
        height: calc(100vh - $sp-nav-des);
        background-color: transparent;
        padding: $sp-lg 0;
        display: flex;
        width: fit-content;

        &.--open,
        &.--close {
          width: fit-content;
          animation: openSidebar 0.25s forwards;
        }

        .sidebar-content {
          padding: 0;
          width: 200px;
          background-color: transparent;
          border: 1px solid $secondary-bg-color;
          box-shadow: none;
          border-radius: $br-sm;
          left: 0;

          &.--open,
          &.--close {
            width: 200px;
            animation: openSidebar 0.25s forwards;
          }

          .sidebar-items {
            display: block;
            &.--open {
              display: block;
            }
          }
        }
      }
      .content-wrap {
        padding-top: $sp-lg;
        height: calc(100vh - $sp-nav-des);
        padding-right: $sp-sm;
        overflow-y: auto;
        position: relative;

        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }
      }
    }
  }
}
@media only screen and (min-width: 1000px) {
  .home-view {
    .home-inner {
      width: 960px;
    }
  }
}
</style>
