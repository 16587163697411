<template>
  <div class="org-info">
    <div class="org-inner">
      <div class="org-banner-wrap">
        <uppy-photo
          v-if="company.cover_pic"
          class="org-banner"
          :imgName="company.cover_pic"
        ></uppy-photo>
        <img
          v-else
          src="@/assets/imgs/dummy-trek/trek-banner.png"
          alt=""
          class="org-banner"
        />
        <div class="org-banner-fade"></div>
        <div class="org-profile-wrap">
          <uppy-photo
            v-if="company.logo"
            class="org-profile-logo"
            :imgName="company.logo"
          ></uppy-photo>
          <img
            v-else
            src="@/assets/imgs/dummy-trek/trek-logo.png"
            alt=""
            class="org-profile-logo"
          />
          <div class="org-profile-name-wrap">
            <h3 class="org-profile-name">{{ company?.company_name }}</h3>
            <p class="org-profile-username">{{ company?.company_username }}</p>
          </div>
        </div>
      </div>

      <div class="org__stats">
        <div class="org__stats-item">
          <img
            src="@/assets/icons/time-past.svg"
            alt=""
            class="org__stats-item-icon"
          />
          <h6 class="org__stats-item-value">
            Since {{ new Date(company.establishment_date).getFullYear() }}
          </h6>
        </div>
        <div class="org__stats-item">
          <img
            src="@/assets/icons/mountains.svg"
            alt=""
            class="org__stats-item-icon"
          />
          <h6 class="org__stats-item-value">
            {{ company.events_completed }} events
          </h6>
        </div>
        <div class="org__stats-item">
          <img
            src="@/assets/icons/user-love.svg"
            alt=""
            class="org__stats-item-icon"
          />
          <h6 class="org__stats-item-value">
            {{ company.no_of_customers }} happy customers
          </h6>
        </div>
      </div>

      <div class="org__info">
        <div class="org__info__states"></div>
        <p class="org__info-desc" :class="{ '--short': descShort }">
          <span class="org__info-desc-text" :class="{ '--short': descShort }"
            >{{ company.description }}
          </span>
          <strong class="org__info-desc-more" @click="descShort = !descShort">{{
            descShort ? "Read more" : "Read less"
          }}</strong>
        </p>
      </div>

      <div class="org__photos-wrap">
        <scrollabel-photos :photos="company.photos"></scrollabel-photos>
      </div>

      <!-- <div class="org__coordinators-wrap">
          <h3 class="org__coordinators-title">Coordinators</h3>
          <div class="org__coordinators">
            <div class="org__coordinators__item" v-for="i in 3" :key="i">
              <img
                src="@/assets/imgs/dummy-trek/co-dp.jpg"
                alt=""
                class="item-dp"
              />
              <div class="item-info">
                <div class="item-info-identity">
                  <h4 class="item-info-identity-name">Ashish Bharambe</h4>
                  <h5 class="item-info-identity-age">(23 yrs)</h5>
                </div>
                <p class="item-info-expirence">
                  <strong>3 years of trekking experience</strong>
                </p>
              </div>
            </div>
          </div>
        </div> -->
    </div>
  </div>
</template>

<script>
import ScrollabelPhotos from "@/components/common/ScrollabelPhotos.vue";
import UppyPhoto from "@/components/common/UppyPhoto.vue";
export default {
  components: { ScrollabelPhotos, UppyPhoto },
  props: ["company"],
  data() {
    return {
      descShort: true,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.org-info {
  width: 100%;
  margin-top: -$sp-lg;
  .org-inner {
    width: 100%;
    .org-banner-wrap {
      position: relative;
      .org-banner {
        width: 100%;
        height: 100px;
        object-fit: cover;
      }

      .org-banner-fade {
        height: 60px;
        width: 100%;
        background-image: linear-gradient(#070a1200, #070a12);
        position: absolute;
        bottom: 0;
      }

      .org-profile-wrap {
        position: absolute;
        bottom: -$sp-xl;
        left: $sp-lg;
        display: flex;
        align-items: center;
        gap: $sp-md;
        cursor: pointer;

        .org-profile-logo {
          width: 62px;
          height: 62px;
          border-radius: 50px;
          border: 1px solid $primary-text-color;
        }

        .org-profile-name-wrap {
          display: flex;
          flex-direction: column;
          justify-content: centers;
          gap: 3px;

          .org-profile-name {
            font-size: $txt-md;
            margin-top: 3px;
          }
          .org-profile-username {
            font-size: $txt-sm;
            color: $tertiary-text-color;
          }
        }
      }
    }

    .org__stats {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: $sp-md $sp-xl;
      padding: $sp-xs $sp-lg;
      margin: $sp-xxxl 0 $sp-lg;
      background-color: $primary-bg-color;

      .org__stats-item {
        display: flex;
        align-items: flex-end;
        gap: $sp-sm;

        .org__stats-item-icon {
          width: $icon-w-xs;
        }
        .org__stats-item-value {
          font-size: $txt-xs;
        }
      }
    }

    .org__info {
      // display: none;
      .org__info-desc {
        padding: 0 $sp-lg;
        font-size: $txt-xs;
        font-weight: 300;
        color: $secondary-text-color;
        text-align: justify;
        margin: $sp-lg 0 $sp-lg;
        line-height: $dis-20;

        .org__info-desc-text {
          white-space: pre-wrap;
          &.--short {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
          }
        }

        .org__info-desc-more {
          color: $primary-text-color;
          font-weight: 700;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .org__photos-wrap {
      // display: none;
      padding: 0 $sp-lg;
    }

    .org__coordinators-wrap {
      // display: none;
      padding: $sp-lg;
      .org__coordinators-title {
        margin-bottom: $sp-md;
        color: $secondary-text-color;
        font-size: $txt-sm;
      }
      .org__coordinators {
        width: 100%;
        overflow-x: scroll;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        gap: $sp-lg;
        .org__coordinators__item {
          display: flex;
          align-items: center;
          gap: $sp-md;
          margin-bottom: $sp-lg;
          background-color: $secondary-bg-color;
          border-radius: 50px;
          padding: $sp-sm $sp-xl $sp-sm $sp-sm;

          .item-dp {
            width: 54px;
            height: 54px;
            object-fit: cover;
            border-radius: 100px;
            box-shadow: 0 0 1px #ffffff;
          }
          .item-info {
            display: flex;
            flex-direction: column;
            gap: $sp-xs;
            white-space: nowrap;

            .item-info-identity {
              display: flex;
              align-items: center;
              gap: $sp-xs;

              .item-info-identity-name {
                font-size: $txt-xs;
                font-weight: 600;
              }
              .item-info-identity-age {
                font-weight: 300;
              }
            }
            .item-info-expirence {
              font-size: $txt-xxs;
              color: $secondary-text-color;
            }
          }
        }
      }
    }
  }
}

// for desktop
@media (min-width: 600px) {
  .org-info {
    // height: calc(100vh - 60px);
    overflow-y: auto;
    background-color: $secondary-bg-color;
    position: relative;
    z-index: 11;
    .org-inner {
      .org-banner-wrap {
        margin-bottom: 100px;
        .org-banner {
          height: 150px;
        }
        .org-banner-fade {
          display: none;
        }

        .org-profile-wrap {
          width: 100%;
          left: 0;
          bottom: -80px;
          flex-direction: column;
          justify-content: center;

          .org-profile-logo {
            width: 72px;
            height: 72px;
            box-shadow: 3px 3px 25px $shadow-color-xl;
          }

          .org-profile-name-wrap {
            align-items: center;

            .org-profile-name {
              font-size: $txt-xl;
            }
          }
        }
      }

      .org__stats {
        display: flex;
        justify-content: center;
        padding: $sp-md $sp-lg;
        margin: $sp-sm 0 $sp-lg;
      }

      .org__info {
        display: block;
        .org__info-desc {
          font-size: $txt-sm;
          margin: $sp-lg 0;
        }
      }

      .org__photos-wrap {
        display: block;
        padding: 0 $sp-lg;
      }

      .org__coordinators-wrap {
        display: block;
        .org__coordinators-title {
          margin-bottom: $sp-lg;
          font-size: $txt-md;
        }
        .org__coordinators {
          .org__coordinators__item {
            display: flex;
            align-items: center;
            gap: $sp-md;
            padding: $sp-md $sp-xl $sp-md $sp-md;
            background-color: $primary-bg-color;
            .item-dp {
              width: 64px;
              height: 64px;
            }
            .item-info {
              .item-info-identity {
                .item-info-identity-name {
                  font-size: $txt-md;
                  font-weight: 600;
                }
                .item-info-identity-age {
                  font-weight: 300;
                }
              }
              .item-info-expirence {
                font-size: $txt-xs;
                color: $secondary-text-color;
              }
            }
          }
        }
      }
    }
  }
}
</style>
