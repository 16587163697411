<template>
  <div class="sc__pics">
    <div class="sc__pics-inner">
      <uppy-photo
        v-for="(photo, index) in photos"
        :key="index"
        :imgName="photo"
        alt=""
        class="sc__pics-img"
      ></uppy-photo>
    </div>
  </div>
</template>

<script>
import UppyPhoto from "@/components/common/UppyPhoto.vue";
export default {
  components: { UppyPhoto },
  props: ["photos"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.sc__pics {
  width: 100%;
  margin: $sp-lg 0;
  .sc__pics-inner {
    display: flex;
    flex-wrap: nowrap;
    gap: $sp-md;
    padding-bottom: $sp-sm;
    width: 100%;
    // scrollbar-gutter: stable;
    box-sizing: border-box;
    // overflow: hidden;
    overflow: auto;
    &:hover {
    }
    .sc__pics-img {
      height: 150px;
      object-fit: cover;
      border-radius: $br-sm;
    }
  }
}

@media (min-width: 600px) {
  .sc__pics {
    .sc__pics-inner {
      .sc__pics-img {
        width: 250px;
        object-fit: cover;
        border-radius: $br-sm;
      }
    }
  }
}
</style>
