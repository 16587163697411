<template>
  <div class="dashboard-right-wrap">
    <div class="section-breadcrumb" @click="$router.back()">
      <img src="@/assets/icons/angle-left.svg" alt="" class="back-icon" />
      <h4 class="breadcrumb-item">Customer:</h4>
    </div>
    <!-- <div
      v-show="loadingCustomer"
      class="shimmers"
      v-for="s in loadingCustomer * 4"
      :key="s"
    ></div> -->
    <div class="booking-card" v-if="customer">
      <h3 class="heading-lg">{{ customer.name }}</h3>
      <h5 class="content-sm">{{ customer.email }}</h5>
      <h5 class="content-sm">{{ customer.mob_no }}</h5>
      <div class="contact-btns">
        <a
          :href="`https://wa.me/91${customer.mob_no}`"
          class="pill_btn --small --hollow --left-icon"
        >
          <img
            src="@/assets/icons/whatsapp.svg"
            alt=""
            class="contact-icon"
          />Whatsapp
        </a>
        <a
          :href="`tel:${customer.mob_no}`"
          class="pill_btn --small --hollow --left-icon"
        >
          <img src="@/assets/icons/call.svg" alt="" class="contact-icon" />Call
        </a>
      </div>
    </div>

    <!-- BOOKINGS LIST SECTION -->
    <div class="dashboard-bookings-wrap">
      <div
        class="no-bookings"
        v-if="!loadingCustomer && (!bookings || (bookings && !bookings.length))"
      >
        <h4>No Bookings in {{ activeTab }} yet!</h4>
      </div>
      <div
        v-show="loadingCustomer"
        class="shimmers"
        v-for="s in loadingCustomer * 6"
        :key="s"
      ></div>

      <!-- BOOKINGS LIST -->
      <div class="bookings-list" v-if="bookings?.length">
        <div
          class="booking-list-item"
          v-for="(booking, bi) in bookings"
          :key="booking._id"
        >
          <div class="booking-list-item-short">
            <div
              class="booking-item-details"
              :class="{
                '--full-width': ['booked', 'confirmed', 'checked-in'].includes(
                  booking.booking_status
                ),
              }"
            >
              <div class="customer-line">
                <div class="booking-customer-name">
                  {{ booking.customer.customer_name }}
                </div>
                <div class="booking-seats">
                  <img
                    src="@/assets/icons/user.svg"
                    alt=""
                    class="booking-seats-icon"
                  />
                  {{ booking.seats.length }}
                </div>
              </div>
              <div class="hr m-0 --dashed"></div>
              <div class="pickup-line">
                <div class="booking-id">
                  {{ booking.booking_id }}
                </div>

                <div
                  class="booking-pickup-address"
                  v-if="booking.ticket?.ticket_heading"
                >
                  <img
                    src="@/assets/icons/ticket.svg"
                    alt=""
                    class="booking-location-icon"
                  />
                  {{ `${booking?.ticket?.ticket_heading}` }}
                </div>
              </div>
            </div>
            <div
              class="booking-item-actions"
              :class="{ '--disabled': loadUpdate.includes(booking._id) }"
              v-if="
                ['accepted', 'booked', 'confirmed'].includes(
                  booking.booking_status
                )
              "
            >
              <!-- <div
                  class="booking-status-tag"
                  v-if="booking.booking_status !== 'pending'"
                >
                  {{ booking.booking_status }}
                </div> -->
              <div
                class="booking-item-actions-accept"
                @click="changeStatus(booking._id, 'booked', bi)"
                v-if="booking.booking_status == 'accepted'"
              >
                Mark Paid
              </div>
              <div
                class="booking-item-actions-accept"
                @click="changeStatus(booking._id, 'checked-in', bi)"
                v-if="['booked', 'confirmed'].includes(booking.booking_status)"
              >
                Check In
              </div>
              <div
                class="booking-item-actions-accept"
                :class="{ '--disabled': booking.booking_status == 'accepted' }"
                @click="changeStatus(booking._id, 'accepted', bi)"
                v-if="booking.booking_status == 'pending'"
              >
                {{
                  booking.booking_status !== "accepted" ? "Accept" : "Accepted"
                }}
              </div>
              <div
                class="booking-item-actions-details"
                @click="changeStatus(booking._id, 'rejected', bi)"
                v-if="booking.booking_status == 'pending'"
              >
                Reject
              </div>
              <div
                class="booking-item-actions-details"
                @click="changeStatus(booking._id, 'pending', bi)"
                v-if="booking.booking_status == 'rejected'"
              >
                Un-Reject
              </div>
            </div>
          </div>
          <div
            class="booking-list-item-info"
            v-if="
              ['accepted', 'booked', 'checked-in'].includes(
                booking.booking_status
              )
            "
          >
            <div
              class="booking-customer-info"
              v-if="openedBookingDetails.includes(booking._id)"
            >
              <p class="booking-customer-info-item">
                <span>Contact No:</span> {{ booking.customer.contact_no }}
              </p>
              <p class="booking-customer-info-item">
                <span>Email:</span> {{ booking.customer.contact_email }}
              </p>
              <div class="booking-customer-info-item">
                <span>Pickup:</span>
                <p>
                  {{ `${booking.pickup_point.full_address}` }}
                </p>
              </div>
              <p class="booking-customer-info-item">
                <span>Payment:</span> {{ booking.payment_status }}
              </p>

              <div class="booking-list-item-info-actions">
                <a :href="`tel:${booking.customer.contact_no}`">
                  <img
                    src="@/assets/icons/call.svg"
                    alt=""
                    class="booking-list-item-info-actions-icon --call"
                  />
                </a>
                <a :href="`https://wa.me/91${booking.customer.contact_no}`">
                  <img
                    src="@/assets/icons/whatsapp.svg"
                    alt=""
                    class="booking-list-item-info-actions-icon --wp"
                  />
                </a>
                <!-- <img
                    src="@/assets/icons/call.svg"
                    alt=""
                    class="booking-list-item-info-actions-icon --call"
                  /> -->
                <div
                  class="pill_btn --small"
                  @click="openedBookingDetailsPage(booking._id)"
                >
                  View Details
                </div>
              </div>
            </div>
            <div
              class="booking-list-item-info-toggle"
              @click="toggleDetails(booking._id)"
            >
              <img
                src="@/assets/icons/angle-small-down.svg"
                alt=""
                class="booking-list-item-info-toggle-icon"
                :class="{
                  '--open': openedBookingDetails.includes(booking._id),
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Bookings List -->
    </div>
  </div>
</template>

<script>
import CustomerGetters from "@/services/customers/GetterServices";
import computedMixin from "@/mixins/computedMixin";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  mixins: [computedMixin],
  data() {
    return {
      loadingCustomer: false,
      customer: null,
      bookings: null,
      loadUpdate: [],
      openedBookingDetails: [],
    };
  },
  computed: {
    customerId() {
      return this.$route.query.cid;
    },
  },
  mounted() {
    this.getCustomerDetails();
  },
  methods: {
    async getCustomerDetails() {
      this.loadingCustomer = true;
      try {
        const customer = await CustomerGetters.companyCustomerById(
          this.customerId,
          this.companyId
        );
        this.customer = customer.customer;
        this.bookings = customer.bookings;
        // console.log(customer);
      } catch (error) {
        console.log(error);
      }
      this.loadingCustomer = false;
    },
    convertDate(d) {
      const date = new Date(d); // Replace this with your desired date

      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };

      const formattedDate = date.toLocaleDateString("en-US", options);
      return formattedDate;
    },
    toggleDetails(id) {
      if (this.openedBookingDetails.includes(id)) {
        // If the ID exists, remove it from the array
        const index = this.openedBookingDetails.indexOf(id);
        this.openedBookingDetails.splice(index, 1);
      } else {
        // If the ID doesn't exist, add it to the array
        this.openedBookingDetails.push(id);
      }
    },
    openedBookingDetailsPage(bid) {
      this.$router.push({
        query: {
          bid: bid,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.shimmers {
  height: 180px; /* Set the desired height of your card */
  border-radius: $br-sm;
  overflow: hidden;
  background-color: $secondary-bg-color-hover;
  margin: $sp-md 0;

  /* Apply the shimmer effect */
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, transparent, #070a123b, transparent);
    animation: shimmer 2s infinite;
  }

  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}

.section-breadcrumb {
  display: flex;
  align-items: center;
  gap: $sp-md;
  padding: $sp-sm 0;
  cursor: pointer;
  .back-icon {
    width: $icon-w-sm;
    cursor: pointer;
  }

  .breadcrumb-item {
    color: $secondary-text-color;

    &.--current {
      color: $primary-text-color;
    }
  }
}

.booking-card {
  background-color: $secondary-bg-color;
  border-radius: $br-sm;
  padding: $sp-md;
  margin: $sp-md 0;
}
.heading-lg {
  font-size: $txt-lg;
  font-weight: 600;
  margin-bottom: $sp-sm;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.heading-md {
  font-size: $txt-md;
  font-weight: 400;
  margin-bottom: $sp-xs;
}
.heading-sm {
  font-size: $txt-sm;
  font-weight: 600;
  margin-bottom: $sp-xs;
  text-transform: capitalize;
}
.content-sm {
  font-size: $txt-sm;
  font-weight: 400;
  margin-bottom: $sp-xxs;
}
.contact-btns {
  display: flex;
  align-items: center;
  gap: $sp-md;
}

.seats-table {
  display: flex;
  flex-direction: column;
  gap: $sp-md;

  .seat-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.payment-status {
  font-size: $txt-md;
  text-transform: capitalize;
}
.price-table {
  display: flex;
  flex-direction: column;
  gap: $sp-md;

  .price-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.bookings-list {
  padding: $sp-lg 0 70px;
  display: flex;
  flex-direction: column;
  gap: $sp-md;

  .booking-list-item {
    background-color: $secondary-bg-color-hover;
    border-radius: $br-sm;
    .booking-list-item-short {
      background-color: $secondary-bg-color;
      padding: $sp-md;
      border-radius: $br-sm;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: $sp-lg;
      box-shadow: 0 4px 15px $shadow-color-lg;

      .booking-item-details {
        display: flex;
        flex-direction: column;
        width: 80%;
        gap: $sp-md;

        &.--full-width {
          width: 100%;
        }

        .customer-line {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: $sp-xl;
          max-width: 100%;

          .booking-customer-name {
            font-size: $txt-md;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 230px;
          }
        }
        .booking-id {
          font-size: $txt-xs;
          font-weight: 600;
          white-space: nowrap;
        }
        .booking-seats {
          display: flex;
          align-items: center;
          gap: $sp-xs;
          font-size: $txt-sm;

          .booking-seats-icon {
            width: $icon-w-xxs;
            height: $icon-w-xxs;
          }
        }
        .booking-location-icon {
          width: $icon-w-xxs;
          height: $icon-w-xxs;
        }
        .pickup-line {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          font-size: $txt-xs;
          gap: $sp-md $sp-xxxl;
        }
        .booking-pickup-address {
          display: flex;
          align-items: center;
          gap: $sp-xs;
          font-size: $txt-sm;
          color: $secondary-text-color;
        }
      }
      .booking-item-actions {
        display: flex;
        flex-direction: column;
        gap: $sp-sm;
        .booking-status-tag {
          text-transform: capitalize;
          //background-color: $secondary-bg-color-hover;
          padding: $sp-xs;
          border-radius: $br-xs;
          font-size: $txt-xs;
          text-align: right;
        }
        .booking-item-actions-accept {
          text-align: center;
          background-color: $theme-bg-color;
          padding: $sp-xs $sp-md;
          border-radius: $br-xs;
          font-size: $txt-xs;
          white-space: nowrap;
          cursor: pointer;

          &:hover {
            background-color: $theme-bg-color-hover;
          }
        }
        .booking-item-actions-details {
          background-color: $error-bg-color;
          padding: $sp-xs $sp-md;
          border-radius: $br-xs;
          font-size: $txt-xs;
          white-space: nowrap;
          cursor: pointer;

          &:hover {
            background-color: $error-bg-color-hover;
          }
        }
      }
    }
    .booking-list-item-info {
      padding: $sp-md $sp-md 0;
      .booking-customer-info {
        padding-bottom: $sp-md;
        display: flex;
        flex-direction: column;
        gap: $sp-xs;
        .booking-customer-info-item {
          display: flex;
          font-size: $txt-xs;
          color: $secondary-text-color;

          span {
            color: $primary-text-color;
            margin-right: $sp-sm;
            display: inline-block;
            width: 80px;
          }
        }
        .booking-list-item-info-actions {
          display: flex;
          align-items: center;
          gap: $sp-xl;
          padding: $sp-lg $sp-sm 0;

          a {
            display: flex;
            align-items: center;
          }
          .booking-list-item-info-actions-icon {
            width: $icon-w-sm;
            cursor: pointer;

            &.--wp {
              transform: scale(1.2);
            }
            &.--call {
              // transform: scale(1.1);
            }
          }
        }
      }
      .booking-list-item-info-toggle {
        text-align: center;
        .booking-list-item-info-toggle-icon {
          width: $icon-w-sm;

          &.--open {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
</style>
