<template>
  <div class="inquiries-view">
    <div class="inquiries-list" v-if="loadingInquiries">
      <div
        class="inquiries-list-item shimmers"
        v-for="s in loadingInquiries * 6"
        :key="s"
      ></div>
    </div>
    <div class="inquiries-list" v-if="inquiries?.length">
      <div
        class="inquiries-list-item"
        v-for="(inquiry, i) in inquiries"
        :key="i"
        @click="openInquiryDetails(inquiry)"
      >
        <h5>{{ inquiry?.customer.customer_name }}</h5>
        <img
          class="inquiries-list-item-angle-icon"
          src="@/assets/icons/angle-right.svg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import InquiryGetters from "@/services/inquiries/GettersServices";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  props: ["company", "tab"],
  data() {
    return {
      loadingInquiries: false,
      page: 1,
      inquiries: [],
    };
  },
  watch: {
    tab: {
      handler() {
        this.inquiries = [];
        this.loadingInquiries = true;
        this.getInquiries();
      },
      deep: true,
    },
  },
  mounted() {
    this.getInquiries();
  },
  methods: {
    async getInquiries() {
      try {
        this.loadingInquiries = true;
        const data = await InquiryGetters.companyInquiries(
          this.company._id,
          this.tab
        );
        this.inquiries = data;
        this.loadingInquiries = false;
        // console.log(this.inquiries);
      } catch (error) {
        this.loadingInquiries = false;
        console.log(error);
      }
    },
    openInquiryDetails(inquiry) {
      this.$router.push({ query: { section: "inquiries", iid: inquiry._id } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.shimmers {
  height: 24px; /* Set the desired height of your card */
  border-radius: $br-sm;
  overflow: hidden;
  background-color: $secondary-bg-color-hover;

  /* Apply the shimmer effect */
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, transparent, #070a123b, transparent);
    animation: shimmer 2s infinite;
  }

  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}

.inquiries-view {
  padding: $sp-lg 0;
  .inquiries-list {
    display: flex;
    flex-direction: column;
    gap: $sp-sm;

    .inquiries-list-item {
      background-color: $secondary-bg-color;
      padding: $sp-md $sp-md;
      border-radius: $br-sm;
      font-size: $txt-lg;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      .inquiries-list-item-angle-icon {
        width: $icon-w-sm;
      }
    }
  }
}
</style>
