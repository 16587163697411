<template>
  <div class="create-card-wrap">
    <div class="create-card-header">
      <slot name="header"></slot>
      <!-- <step-bar-city
        :steps="createCompanySteps"
        :currentStep="currenCreateStep + 1"
        :isClickable="$route.query.edit"
      ></step-bar-city> -->
    </div>
    <div class="create-card">
      <slot></slot>
    </div>
  </div>
</template>

<script>
// import StepBarCity from "@/components/common/StepBarCity.vue";
export default {
  //   components: { StepBarCity },
  data() {
    return {
      createCompanySteps: [
        {
          name: "New Company",
          viewName: "CreateNewCompany",
        },
        {
          name: "Contact Info",
          viewName: "CreateContact",
        },
        {
          name: "Locations",
          viewName: "CreateCompanyLocations",
        },
      ],
    };
  },
  computed: {
    currenCreateStep() {
      let step = this.createCompanySteps.findIndex(
        (step) => step.viewName == this.$route.name
      );
      return step;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.create-card-wrap {
  margin: 0 0;
  .create-card-header {
    font-size: $txt-md;
    width: 100%;
  }
  .create-card {
    margin-top: $sp-md;
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .create-card-wrap {
    margin: 30px 0;
    .create-card-header {
      font-size: $txt-lg;
      width: 100%;
    }
  }
}
</style>
