import axios from "axios";
import constants from "@/constants";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// import computedMixin from "./computedMixin";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const url = `${constants.apiUrl}`;

class CompanyGetters {
  static async userCompanies(passedData) {
    try {
      return new Promise((resolve, reject) => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            auth.currentUser
              .getIdToken(/* forceRefresh */ true)
              .then((idToken) => {
                const url = `${constants.apiUrl}/company-getters/user-companies`;
                const config = {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    Authorization: `Bearer ${idToken}`,
                  },
                };

                axios
                  .post(url, passedData, config)
                  .then((response) => {
                    const data = response.data;
                    unsubscribe();
                    resolve(data);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            reject("user not found");
          }
        });
      });
    } catch (error) {
      return error;
    }
  }
  static async publicProfile(username) {
    return await axios.get(
      `${url}/company-getters/public-profile/${username}`,
      (res, err) => {
        if (res) {
          return res;
        } else {
          return err;
        }
      }
    );
  }
  static async companyById(companyId) {
    return await axios.get(
      `${url}/company-getters/by-id/${companyId}`,
      (res, err) => {
        if (res) {
          return res;
        } else {
          return err;
        }
      }
    );
  }
  static async checkUsername(username, companyId) {
    return await axios.get(
      `${url}/company-getters/check-username/${username}?companyId=${companyId}`,
      (res, err) => {
        if (res) {
          return res;
        } else {
          return err;
        }
      }
    );
  }
  // static async companyById(companyId) {
  //   try {
  //     return new Promise((resolve, reject) => {
  //       const auth = getAuth();
  //       const unsubscribe = onAuthStateChanged(auth, (user) => {
  //         if (user) {
  //           auth.currentUser
  //             .getIdToken(/* forceRefresh */ true)
  //             .then((idToken) => {
  //               const fetchUrl = `${constants.apiUrl}/company-getters/user-companies`;

  //               axios
  //                 .post(fetchUrl, { idToken })
  //                 .then((response) => {
  //                   console.log(data.data);
  //                   const data = response.data;
  //                   unsubscribe();
  //                   resolve(data);
  //                 })
  //                 .catch((error) => {
  //                   // put alert
  //                   console.log(error);
  //                   reject(error);
  //                 });
  //             })
  //             .catch((err) => {
  //               reject(err);
  //             });
  //         }
  //       });
  //     });
  //   } catch (error) {
  //     this.handleGetCompanies(error, "error");
  //     toast.error(error, {
  //       autoClose: 3000,
  //       hideProgressBar: true,
  //     });
  //   }
  // }
}

export default CompanyGetters;
