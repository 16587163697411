<template>
  <datepicker
    class="datepicker-input"
    v-model="date"
    input-class-name="dp-input-styles"
    dark
    auto-apply
    :partial-range="false"
    placeholder="Date"
    :enable-time-picker="false"
    @update:model-value="selectDates"
    :position="'left'"
    :month-change-on-scroll="false"
    :teleport="true"
  />
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  props: ["selectedDates", "dark"],
  components: { Datepicker },
  data() {
    return {
      date: null,
    };
  },
  watch: {
    selectedDates: {
      handler(newValue, oldValue) {
        if (this.selectedDates?.length) {
          this.date = this.selectedDates;
        }
      },
      deep: true,
    },
  },
  methods: {
    selectDates() {
      this.$emit("datesSelected", this.date);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.dp__theme_dark {
  --dp-background-color: #252936;
  --dp-text-color: #ffffff;
  --dp-hover-color: #b6bbc8;
  --dp-hover-text-color: #d3d3de;
  --dp-hover-icon-color: #d3d3de;
  --dp-primary-color: #7e9cff;
  --dp-primary-text-color: #ffffff;
  --dp-secondary-color: #d8d8d8;
  --dp-border-color: #7e9cff00;
  --dp-menu-border-color: #7e9cff94;
  --dp-border-color-hover: #7e9cff;
  --dp-disabled-color: #737373;
  --dp-scroll-bar-background: #212121;
  --dp-scroll-bar-color: #484848;
  --dp-success-color: #00701a;
  --dp-success-color-disabled: #428f59;
  --dp-icon-color: #e0e0e0;
  --dp-danger-color: #e53935;
  --dp-highlight-color: rgba(0, 92, 178, 0.2);
}
.--dark {
  --dp-background-color: #212121;
}
.datepicker-input {
  width: 100%;
  border-radius: 10px;
}
.dp-input-styles {
  height: 40px !important;
  border-radius: 10px;
  border: 1px solid #7e9cff99;
}
</style>
