<template>
  <div class="stepbar">
    <div class="cancel-btn" @click="goBack()">
      <img
        src="@/assets/icons/arrow-left.svg"
        alt=""
        class="cancel-btn-icon"
      />cancel
    </div>
    <div class="stepbar-inner" :class="{ '--disabled-steps': !trekId }">
      <div
        class="stepbar-item"
        :class="{ '--active': page == 'CreateTrekInfo' }"
        @click="changePage('CreateTrekInfo')"
      >
        <div class="stepbar-item-point"></div>
        <div class="stepbar-item-name">Info</div>
      </div>

      <div class="sidebar-hr hr hr --dashed"></div>

      <div
        class="stepbar-item"
        :class="{ '--active': page == 'CreateTrekPhotos' }"
        @click="changePage('CreateTrekPhotos')"
      >
        <div class="stepbar-item-point"></div>
        <div class="stepbar-item-name">Photo</div>
      </div>

      <div class="sidebar-hr hr hr --dashed"></div>

      <div
        class="stepbar-item"
        :class="{ '--active': page == 'CreateTrekItinerary' }"
        @click="changePage('CreateTrekItinerary')"
      >
        <div class="stepbar-item-point"></div>
        <div class="stepbar-item-name">Itine</div>
      </div>

      <div class="sidebar-hr hr hr --dashed"></div>

      <!-- <div
        class="stepbar-item"
        :class="{ '--active': page == 'CreatePriceOptions' }"
        @click="changePage('CreatePriceOptions')"
      >
        <div class="stepbar-item-point"></div>
        <div class="stepbar-item-name">Price</div>
      </div>
      <div class="sidebar-hr hr hr --dashed"></div> -->
      <div
        class="stepbar-item"
        :class="{ '--active': page == 'CreateInstructions' }"
        @click="changePage('CreateInstructions')"
      >
        <div class="stepbar-item-point"></div>
        <div class="stepbar-item-name">Instr</div>
      </div>
      <div class="sidebar-hr hr hr --dashed"></div>
      <div
        class="stepbar-item"
        :class="{ '--active': page == 'CreateTrekEvent' }"
        @click="changePage('CreateTrekEvent')"
      >
        <div class="stepbar-item-point"></div>
        <div class="stepbar-item-name">Event</div>
      </div>
    </div>
  </div>
  <!-- <div class="input-note">
    You can change section by clicking on above stepbar
  </div> -->
</template>

<script>
export default {
  computed: {
    page() {
      return this.$route.name;
    },
    companyId() {
      return this.$route.params.companyId;
    },
    trekId() {
      return this.$route.params.trekId || this.$route.query.trekId;
    },
    templateId() {
      return this.$route.query.tid;
    },
  },
  methods: {
    goBack() {
      if (this.trekId) {
        this.$router.push({
          name: "TrekDashboard",
          params: { trekId: this.trekId },
          query: { section: "trek" },
        });
      } else {
        this.$router.push({
          name: "CompanyDashboard",
          params: { companyId: this.companyId },
          query: { section: "events" },
        });
      }
    },
    changePage(pageName) {
      if (pageName == "CreateTrekInfo") {
        if (this.templateId) {
          this.$router.push({
            name: pageName,
            params: { companyId: this.companyId },
            query: { trekId: this.trekId, tid: this.templateId },
          });
        } else {
          this.$router.push({
            name: pageName,
            params: { companyId: this.companyId },
            query: { trekId: this.trekId },
          });
        }
      } else {
        if (this.templateId) {
          this.$router.push({
            name: pageName,
            params: {
              companyId: this.companyId,
              trekId: this.trekId,
            },
          });
        } else {
          this.$router.push({
            name: pageName,
            params: {
              companyId: this.companyId,
              trekId: this.trekId,
            },
            query: { tid: this.templateId },
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.stepbar {
  margin: $sp-sm 0 $sp-xxl;

  .cancel-btn {
    display: flex;
    align-items: center;
    gap: $sp-sm;
    width: fit-content;
    padding-bottom: $sp-lg;
    cursor: pointer;

    .cancel-btn-icon {
      width: $icon-w-xs;
    }
  }
  .stepbar-inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    // gap: $sp-xs;

    &.--disabled-steps {
      pointer-events: none;
    }

    .stepbar-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: $sp-xs;
      font-size: $txt-xs;
      color: $secondary-text-color;
      cursor: pointer;

      &.--active {
        color: $primary-text-color;

        .stepbar-item-point {
          border: 2px solid $primary-text-color;
        }
      }
      .stepbar-item-point {
        width: $dis-6;
        height: $dis-6;
        border: 2px solid $tertiary-text-color;
        border-radius: 10px;
      }
    }
    .sidebar-hr {
      margin-top: 5px;
      width: 100%;
    }
  }
}

@media (min-width: 600px) {
  .stepbar {
    margin: $sp-xl 0;
  }
}
</style>
