<template>
  <div
    class="drawer-wrap"
    @click="$emit('closeDrawer')"
    :class="{ '--open': openDrawer === true, '--close': openDrawer === false }"
  >
    <div class="drawer-card" @click.stop>
      <div class="drawer-card-inner">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["toggleDrawer"],
  data() {
    return {
      openDrawer: null,
    };
  },
  watch: {
    toggleDrawer: {
      handler(newValue, oldValue) {
        //both newValue and oldValue are the FULL object and not just what changed of it!
        this.openDrawer = this.toggleDrawer;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

@keyframes closeMobDrawerWrap {
  from {
    bottom: 0;
  }
  to {
    bottom: -100vh;
  }
}
@keyframes openMobDrawerWrap {
  from {
    bottom: -100vh;
  }
  to {
    bottom: 0;
  }
}
@keyframes closeMobDrawerCard {
  from {
    bottom: 0;
  }
  to {
    bottom: -80vw;
  }
}
@keyframes openMobDrawerCard {
  from {
    bottom: -80vw;
  }
  to {
    bottom: 0;
  }
}
.drawer-wrap {
  background-color: #25293610;
  backdrop-filter: blur(1px);
  width: 100vw;
  height: 100vh;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 199;
  overflow: hidden;
  bottom: -100vh;
  &.--open {
    animation: openMobDrawerWrap 0.08s forwards;

    .drawer-card {
      animation: openMobDrawerCard 0.08s forwards;
    }
  }
  &.--close {
    animation: closeMobDrawerWrap 0.2s forwards;

    .drawer-card {
      animation: closeMobDrawerCard 0.2s forwards;
    }
  }
  .drawer-card {
    position: absolute;
    bottom: 0;
    background-color: $secondary-bg-color;
    width: 100%;
    height: 80vh;
    overflow: auto;
    border-radius: $br-sm $br-sm 0 0;
    box-shadow: 0 0 15px $shadow-color-lg;
    .drawer-card-inner {
    }
  }
}

@media (min-width: 600px) {
  @keyframes closeDesDrawerWrap {
    from {
      right: 0;
    }
    to {
      right: -1000vw;
    }
  }
  @keyframes openDesDrawerWrap {
    from {
      right: -100vw;
    }
    to {
      right: 0;
    }
  }
  @keyframes closeDesDrawerCard {
    from {
      right: 0;
    }
    to {
      right: -450px;
    }
  }
  @keyframes openDesDrawerCard {
    from {
      right: -450px;
    }
    to {
      right: 0;
    }
  }

  .drawer-wrap {
    bottom: 0;
    right: -100vw;
    &.--open {
      animation: openDesDrawerWrap 0.08s forwards;

      .drawer-card {
        animation: openDesDrawerCard 0.08s forwards;
      }
    }
    &.--close {
      animation: closeDesDrawerWrap 0.2s forwards;

      .drawer-card {
        animation: closeDesDrawerCard 0.2s forwards;
      }
    }
    .drawer-card {
      right: 0;
      width: 450px;
      height: calc(100vh - $sp-nav-des);
      border-radius: 0;
      .drawer-card-inner {
      }
    }
  }
}
</style>
