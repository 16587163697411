<template>
  <div class="dashboard-right-wrap">
    <h3>Event Settings</h3>
    <small>[ {{ event.trek_name }} ]</small>
    <div class="hr"></div>
    <div class="event-settings-card">
      <h4>General Settings</h4>
      <div
        class="navigate-settings-btn pill_btn --right-icon"
        @click="
          $router.push({
            name: 'CreateTrekInfo',
            params: { companyUsername: company?.company_username },
            query: { trekId: event?._id },
          })
        "
      >
        <h5>Open general settings</h5>
        <img src="@/assets/icons/angle-right.svg" alt="" />
      </div>

      <div class="danger-section">
        <h4>Danger Section</h4>

        <div class="pill_btn delete-btn --left-icon" @click="deleteEvent()">
          <img class="delete-btn-icon" src="@/assets/icons/delete.svg" alt="" />
          Delete Event
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventSetterServices from "@/services/trek/SetterServices";
export default {
  props: ["event", "company"],
  computed: {
    eventId() {
      return this.$route.params.trekId;
    },
  },
  methods: {
    async deleteEvent() {
      try {
        if (
          confirm(
            `Do you realy want to delete "${this.event.trek_name}" event?`
          )
        ) {
          const deletedEvent = await EventSetterServices.updateDeleteEvent({
            companyId: this.company._id,
            eventId: this.eventId,
          });
          console.log(deletedEvent, "deleted");
          this.$router.push({
            name: "CompanyDashboard",
            params: { companyId: this.company._id },
          });
        } else {
          console.log("Cancled");
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.dashboard-right-wrap {
  background-color: $secondary-bg-color;
  padding: $sp-md;
  border-radius: $br-sm;
}

.navigate-settings-btn {
  background-color: $primary-bg-color;
  box-shadow: 4px 4px 15px $shadow-color-lg;
  width: fit-content;
  padding: $sp-md $sp-lg;
  margin: $sp-lg 0;
  border-radius: $br-sm;
  cursor: pointer;
  transition: 0.5s all;

  &:hover {
    background-color: $secondary-bg-color;
  }
}

.danger-section {
  margin-top: $sp-xxl;
  .delete-btn {
    background-color: $error-bg-color;
    border: none;
    border-radius: $br-sm;
    margin-top: $sp-lg;

    &:hover {
      background-color: $error-bg-color-hover;
    }

    .delete-btn-icon {
      filter: brightness(0) invert(1);
    }
  }
}
</style>
