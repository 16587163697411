<template>
  <div class="costinc">
    <div class="costinc-inner">
      <div class="costinc__list">
        <h4 class="costinc__list-title"># Includes</h4>
        <div class="costinc__list-item" v-for="item in includes" :key="item">
          <img
            src="@/assets/icons/caret-right.svg"
            alt=""
            class="costinc__list-item-icon"
          />
          <h5 class="costinc__list-item-text">{{ item }}</h5>
        </div>
      </div>
    </div>
    <br />
    <div class="costinc-inner">
      <div class="costinc__list">
        <h4 class="costinc__list-title"># Excludes</h4>
        <div class="costinc__list-item" v-for="item in excludes" :key="item">
          <img
            src="@/assets/icons/caret-right.svg"
            alt=""
            class="costinc__list-item-icon"
          />
          <h5 class="costinc__list-item-text">{{ item }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["includes", "excludes"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.costinc {
  .costinc-inner {
    background-color: $primary-bg-color;
    padding: $sp-lg;
    border-radius: $br-sm;
    .costinc__list {
      .costinc__list-title {
        margin-bottom: $sp-md;
        color: $secondary-text-color;
      }
      .costinc__list-item {
        display: flex;
        align-items: center;
        gap: $sp-sm;
        margin-bottom: $sp-md;
        .costinc__list-item-icon {
          width: $icon-w-md;
          opacity: 0.6;
        }
        .costinc__list-item-text {
          margin-top: 1px;
          font-size: $txt-sm;
        }
      }
    }
  }
}
</style>
