<template>
  <div class="place-photos">
    <div class="place-photos-item" v-for="photo in photos" :key="photo">
      <div class="place-photos-item-inner">
        <UppyPhoto class="photo" :imgName="photo" :key="photo" alt="" />
        <div class="place-photos__actions">
          <div
            class="photo-action-cover"
            :class="{ '--cover': cover == photo }"
            @click="setCover(photo)"
          >
            {{ cover == photo ? "Cover" : "Set cover" }}
          </div>
          <div class="photo-action-delete" @click="deletePhoto(photo)">
            <LoaderSpinner v-if="deleteLoading == photo"></LoaderSpinner>
            <span v-if="!error && deleteLoading != photo">Delete</span>
            <span v-if="error && !deleteLoading">{{ error }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhotoService from "@/services/PhotoService";
import UppyPhoto from "./UppyPhoto.vue";
import LoaderSpinner from "./LoaderSpinner.vue";
export default {
  components: { UppyPhoto, LoaderSpinner },
  props: ["photos", "cover"],
  data() {
    return {
      deleteLoading: false,
      error: "",
    };
  },
  methods: {
    async deletePhoto(photo) {
      this.deleteLoading = photo;
      // const res = await PhotoService.deletePhoto(photo);
      this.deleteLoading = null;

      // if (res.status) {
      this.$emit("photoDeleted", photo);
      // } else {
      //   this.error = res.message;
      //   setTimeout(() => {
      //     this.error = null;
      //   }, 1000);
      // }
    },
    setCover(photo) {
      this.$emit("setCover", photo);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.place-photos {
  margin: 30px 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: $sp-md;
  width: 100%;

  .place-photos-item {
    // width: 100%;
    height: 150px;
    display: inline-block;
    align-items: center;
    // margin-right: 20px;
    border-radius: 10px;
    border: 1px solid #ffffff3f;
    overflow: hidden;

    .place-photos-item-inner {
      // width: 250px;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #252936;
      position: relative;

      .photo {
        height: 150px;
        object-fit: cover;
      }
      .place-photos__actions {
        position: absolute;
        bottom: 0;
        display: flex;
        gap: 10px;
        padding: 10px;
        box-sizing: border-box;
        width: 100%;
        font-size: $txt-xs;

        .photo-action-cover {
          background-color: #6082f1;
          padding: 3px 7px;
          border-radius: 5px;
          cursor: pointer;

          &:hover {
            background-color: $theme-bg-color-hover;
          }

          &.--cover {
            background-color: #fff;
            color: #6082f1;
          }
        }
        .photo-action-delete {
          border: 1px solid #ff6e6e;
          background-color: #ff6e6e;
          padding: 3px 7px;
          border-radius: 5px;
          cursor: pointer;

          &:hover {
            background-color: $error-text-color;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 600px) {
  .place-photos {
    .place-photos-item {
      // width: 30.5%;
      height: 158px;

      .place-photos-item-inner {
        // width: 250px;
        height: 158px;
        .photo {
          height: 158px;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
