<template>
  <div class="trek-info" v-if="trek">
    <div class="info-inner">
      <div class="info__cover-wrap">
        <uppy-photo
          v-if="trek.cover_pic"
          :imgName="trek.cover_pic"
          alt=""
          class="info__cover-img"
        ></uppy-photo>
        <img
          v-else
          src="@/assets/imgs/dummy-trek/trek-cover.png"
          alt=""
          class="info__cover-img"
        />
        <!-- <div class="info__cover-more">
          <img
            src="@/assets/icons/gallery.svg"
            alt=""
            class="info__cover-more-icon"
          />
        </div> -->
      </div>

      <div class="info__main">
        <div class="info__main-name-wrap">
          <img
            src="@/assets/icons/location.svg"
            alt=""
            class="info__main-name-icon"
          />
          <h3 class="info__main-name">{{ trek.trek_name }}</h3>
        </div>
        <p class="info__main-address">
          {{
            `${trek.trek_location.full_address}, ${trek.trek_location.city},
          ${trek.trek_location.state}, ${trek.trek_location.country}`
          }}
        </p>
        <p class="info__main-difficulty">
          {{ trek.trek_difficulty_level }} Difficulty
        </p>
        <div class="info__main-date-wrap">
          <div class="info__main-date">
            <img
              src="@/assets/icons/calendar.svg"
              alt=""
              class="info__main-date-icon"
            />
            <h4 class="info__main-date-text">
              <!-- {{ convertDate(trek.trek_date) }} -->
              {{ trek.itinerary.length }} days
            </h4>
          </div>
        </div>

        <p class="info__main-description">
          <span
            class="info__main-description-text"
            :class="{ '--short': descShort }"
            >{{ trek.trek_description }}
          </span>
          <strong
            class="info__main-description-more"
            @click="descShort = !descShort"
            >{{ descShort ? "Read more" : "Read less" }}</strong
          >
        </p>

        <div class="info__pics" v-if="trek.photos && trek.photos.length">
          <scrollabel-photos :photos="trek.photos"></scrollabel-photos>
        </div>
      </div>
    </div>
    <!-- <div class="info__ctas">
      <div class="info__ctas-card">
        <div
          class="info__ctas-price"
          @click="showPriceDropdown = !showPriceDropdown"
        >
          <h3 class="info__ctas-price-value">
            ₹ {{ trek.pricing_options[selectedPrice].price_value }}/-
          </h3>
          <p class="info__ctas-price-unit">PP</p>
          <img
            src="@/assets/icons/angle-small-down.svg"
            alt=""
            class="info__ctas-price-icon"
            :class="{ '--open': showPriceDropdown }"
          />
        </div>
        <div class="info__ctas-btn">
          <h4 class="info__ctas-btn-text">Book Now</h4>
          <img
            src="@/assets/icons/angle-right.svg"
            alt=""
            class="info__ctas-btn-icon"
          />
        </div>

        <div class="price-options-dropdown" v-show="showPriceDropdown">
          <div
            class="price-options-item"
            :class="{ '--active': selectedPrice == i }"
            v-for="(price, i) in trek.pricing_options"
            :key="i"
            @click="selectedPrice = i"
          >
            <h4 class="price-options-item-value">
              ₹ {{ price.price_value }}/-
            </h4>
            <h5 class="price-options-item-unit">{{ price.price_unit }}</h5>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import ScrollabelPhotos from "@/components/common/ScrollabelPhotos.vue";
import UppyPhoto from "@/components/common/UppyPhoto.vue";
export default {
  components: { ScrollabelPhotos, UppyPhoto },
  props: ["trek"],
  data() {
    return {
      descShort: true,
      showPriceDropdown: false,
      selectedPrice: 0,
    };
  },
  methods: {
    convertDate(d) {
      const date = new Date(d); // Replace this with your desired date

      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };

      const formattedDate = date.toLocaleDateString("en-US", options);
      return formattedDate;
    },
    countDaysAndNights() {
      const itinerary = this.trek.itinerary;
      const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

      const startDate = new Date(itinerary[0].date);
      startDate.setHours(0, 0, 0, 0); // Set the time to 00:00:00

      const endDate = new Date(itinerary[itinerary.length - 1].date);
      endDate.setHours(0, 0, 0, 0); // Set the time to 00:00:00

      const startDateTime = new Date(
        startDate.getTime() + new Date(itinerary[0].schedule[0].time).getTime()
      );
      const endDateTime = new Date(
        endDate.getTime() +
          new Date(
            itinerary[itinerary.length - 1].schedule[
              itinerary[itinerary.length - 1].schedule.length - 1
            ].time
          ).getTime()
      );

      // Calculate the difference in milliseconds
      const diffInMilliseconds = Math.abs(
        endDateTime.getTime() - startDateTime.getTime()
      );

      // Convert the difference to days and nights
      const diffInDays = Math.floor(diffInMilliseconds / oneDay);
      const diffInNights = diffInDays - 1; // Assuming a night means staying for one full day

      return { days: diffInDays, nights: diffInNights };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.trek-info {
  width: 100%;
  // padding-top: $sp-lg;
  box-sizing: border-box;
  // margin-top: $sp-md;
  // height: 100vh;
  .info-inner {
    width: 100%;
    padding: $sp-md $sp-md;
    box-sizing: border-box;
    background-color: $secondary-bg-color;

    .info__cover-wrap {
      width: 100%;
      position: relative;
      .info__cover-img {
        width: 100%;
        aspect-ratio: 2.6/1;
        border-radius: $br-sm;
        object-fit: cover;
      }
      .info__cover-more {
        position: absolute;
        right: 12px;
        bottom: 12px;
        background-color: #fff;
        box-shadow: 3px 3px 10px $shadow-color-lg;
        width: 34px;
        height: 34px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .info__cover-more-icon {
          width: $icon-w-sm;
        }

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .info__main {
      .info__main-name-wrap {
        display: flex;
        align-items: center;
        gap: $sp-sm;
        margin: $sp-sm 0;

        .info__main-name-icon {
          width: $icon-w-sm;
        }
        .info__main-name {
          font-size: $txt-md;
        }
      }

      .info__main-address,
      .info__main-difficulty {
        font-size: $txt-sm;
        margin: $sp-md 0;
        color: $secondary-text-color;
        text-transform: capitalize;
      }

      .info__main-difficulty {
        font-size: $txt-xs;
        font-weight: 700;
        width: fit-content;
      }

      .info__main-date-wrap {
        display: flex;

        .info__main-date {
          display: flex;
          align-items: center;
          gap: $sp-md;
          border: 1px solid $primary-bg-color;
          border-radius: 50px;
          padding: $sp-xs $sp-lg;

          .info__main-date-icon {
            width: $icon-w-sm;
          }
          .info__main-date-text {
            font-size: $txt-sm;
            font-weight: 300;
          }
        }
      }

      .info__main-description {
        font-size: $txt-sm;
        color: $secondary-text-color;
        text-align: justify;
        margin: $sp-lg 0;
        line-height: $dis-18;

        .info__main-description-text {
          white-space: pre-wrap;
          &.--short {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }
        }

        .info__main-description-more {
          color: $primary-text-color;
          font-weight: 700;
          text-decoration: underline;
          display: block;
          cursor: pointer;
        }
      }
    }
  }
  .info__ctas {
    width: 100%;
    padding: $sp-sm;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;

    .info__ctas-card {
      background-color: $secondary-bg-color;
      padding: $sp-sm;
      border-radius: $dis-6;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 0 40px 20px $shadow-color-xxl;
      position: relative;

      .info__ctas-price {
        display: flex;
        align-items: center;
        gap: $sp-xs;
        padding: $sp-xs $sp-xxs;
        border-bottom: 1px solid $tertiary-text-color;
        cursor: pointer;

        .info__ctas-price-unit {
          color: $secondary-text-color;
          font-size: $txt-sm;
        }
        .info__ctas-price-value {
          font-size: $txt-md;
        }

        .info__ctas-price-icon {
          width: $icon-w-xs;

          &.--open {
            transform: rotate(180deg);
          }
        }
      }
      .info__ctas-btn {
        background-color: $theme-bg-color;
        padding: $sp-sm $sp-md;
        border-radius: $dis-4;
        display: flex;
        align-items: center;
        gap: $sp-sm;
        cursor: pointer;

        &:hover {
          box-shadow: 2px 2px 10px $shadow-color-lg;
          transform: scale(1.05);
        }

        .info__ctas-btn-text {
          font-size: $txt-md;
        }

        .info__ctas-btn-icon {
          width: $icon-w-xs;
          margin-right: -$sp-sm;
        }
      }

      .price-options-dropdown {
        background-color: $secondary-bg-color;
        position: absolute;
        left: 0;
        bottom: 55px;
        padding: 0;
        border-radius: $br-sm;
        overflow: hidden;
        box-shadow: 0 0 10px $shadow-color-xl;

        .price-options-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 180px;
          padding: $sp-sm $sp-md;

          &:hover {
            background-color: $secondary-bg-color-hover;
          }
          &.--active {
            background-color: $secondary-bg-color-hover;
          }
          .price-options-item-value {
            font-size: $txt-md;
          }
          .price-options-item-unit {
            font-size: $txt-sm;
            color: $secondary-text-color;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}

@media (min-width: 600px) {
  .trek-info {
    margin-top: 0;
    .info-inner {
      padding: $sp-lg $sp-lg;
    }
    .info__ctas {
      width: 50%;
      // overflow: hidden;
    }
  }
}

// for desktop
@media (min-width: 1024px) {
  .trek-info {
    padding-top: 0;
    position: relative;
    .info-inner {
      // height: calc(100vh - 60px);
      // overflow-y: auto;
      box-sizing: border-box;
      padding: $sp-lg $sp-lg;
      .info__cover-wrap {
        .info__cover-img {
          aspect-ratio: 2/1;
        }
        .info__cover-more {
          display: none;
        }
      }

      .info__main {
        margin-top: $sp-md;
        .info__main-name-wrap {
          .info__main-name-icon {
            width: $icon-w-sm;
          }
          .info__main-name {
            font-size: $txt-xl;
          }
        }

        .info__main-address,
        .info__main-difficulty {
          font-size: $txt-sm;
        }
        .info__main-difficulty {
          font-size: $txt-xs;
        }

        .info__main-date-wrap {
          margin-top: $sp-lg;
          .info__main-date {
            .info__main-date-text {
              font-size: $txt-md;
            }
          }
        }

        .info__main-description {
          font-size: $txt-sm;
          line-height: $dis-20;
        }
      }
    }
    .info__ctas {
      width: 100%;
      padding: $sp-lg;
      position: absolute;

      .info__ctas-card {
        padding: $sp-sm;

        .info__ctas-price {
          padding: $sp-sm $sp-sm;
          font-size: $txt-md;

          .info__ctas-price-unit {
            font-size: $txt-sm;
          }

          .info__ctas-price-unit {
            display: flex;
            align-items: center;
            visibility: hidden;
            position: relative;
            width: 100px;
          }
          .info__ctas-price-unit:after {
            visibility: visible;
            position: absolute;
            bottom: 0;
            left: 0;
            width: fit-content;
            content: "Per Person";
          }
        }
        .info__ctas-btn {
          .info__ctas-btn-text {
            font-size: $txt-md;
          }

          .info__ctas-btn-icon {
            width: $icon-w-sm;
          }
        }
      }
    }
  }
}
</style>
