<template>
  <section>
    <header class="navbar">
      <div class="navbar-inner">
        <!-- <div class="navbar-menu" @click="showSidebar = !showSidebar">
          <img src="@/assets/icons/menu.svg" alt="" class="navbar-menu-icon" />
        </div> -->
        <div class="navbar-left">
          <div
            class="navbar-menu"
            @click="$emit('toggleMenu')"
            v-if="!noSidbar"
          >
            <img
              src="@/assets/icons/menu-v2.svg"
              alt=""
              class="navbar-menu-icon"
            />
          </div>
          <div
            class="navbar__brand"
            @click="
              $router.push({
                name: 'CompanyDashboard',
                params: { companyId: company?._id },
              })
            "
          >
            <!-- <img
              src="@/assets/logos/long_logo.svg"
              alt=""
              class="navbar__brand-img"
            /> -->
            <h4 class="navbar__brand-company-name">
              {{ company?.company_name }}
            </h4>
            <p class="navbar__brand-company-username">
              {{ company?.company_username }}
            </p>
          </div>
        </div>
        <div class="navbar-user" @click="showSidebar = !showSidebar">
          <!-- <uppy-photo
            v-if="company.logo"
            :imgName="company.logo"
            class="navbar-user-dp"
          ></uppy-photo> -->
          <img
            src="../../assets/logos/short_logo.svg"
            alt=""
            class="navbar-user-dp --small"
          />
          <img
            src="../../assets/logos/long_logo.svg"
            alt=""
            class="navbar-user-dp --long"
          />
          <!-- <h4 class="navbar-user-name">{{ company.company_name }}</h4> -->
          <!-- <img
            src="../../assets/icons/angle-small-down.svg"
            alt=""
            class="navbar-user-icon"
          /> -->
        </div>
      </div>
    </header>
    <!-- <div
      v-show="showSidebar"
      class="sidebar"
      :class="showSidebar ? '--open' : '--close'"
      @click="showSidebar = false"
    >
      <div class="sidebar-inner">
        <div class="sidebar-header"></div>
        <div class="sidebar-item" @click="logout()">
          <img
            src="@/assets/icons/sign-out.svg"
            alt=""
            class="sidebar-item-icon"
          />
          <p class="sidebar-item-label">Log out</p>
        </div>
      </div>
    </div> -->
  </section>
</template>

<script>
import { getAuth } from "firebase/auth";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import computedMixin from "@/mixins/computedMixin";
export default {
  mixins: [computedMixin],
  props: ["noSidbar"],
  data() {
    return {
      showSidebar: false,
    };
  },
  methods: {
    logout() {
      const auth = getAuth();
      auth.signOut().then(
        () => {
          this.user = null;
          this.$router.push("/login");
        },
        function (error) {
          toast.error("Something went wrong while loging out", {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.navbar {
  width: 100vw;
  height: $sp-nav-mob;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: $sp-sm;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $secondary-bg-color;
  box-shadow: 0 -5px 15px 10px #00000070;
  z-index: 1000;
  .navbar-inner {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .navbar-left {
      display: flex;
      align-items: center;
      gap: $sp-md;
      .navbar__brand {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .navbar__brand-img {
          width: 80px;
        }

        .navbar__brand-company-name {
          font-size: $txt-md;
          line-height: $txt-md;
        }
        .navbar__brand-company-username {
          font-size: $txt-sm;
          line-height: $txt-md;
          color: $secondary-text-color;
          margin-bottom: -4px;
        }
      }
    }
    .navbar-menu {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $secondary-bg-color-hover;
      width: 36px;
      height: 36px;
      border-radius: 40px;

      .navbar-menu-icon {
        width: $icon-w-sm;
        filter: invert(0.8);
        cursor: pointer;
      }
    }

    .navbar-user {
      display: flex;
      align-items: center;
      gap: $sp-sm;
      cursor: pointer;

      .navbar-user-dp {
        width: 36px;
        height: 36px;
        border-radius: 40px;

        &.--long {
          display: none;
        }
        &.--small {
          display: block;
        }
      }
      .navbar-user-name,
      .navbar-user-icon {
        display: none;
      }
    }
  }
}

@keyframes openSidebar {
  from {
    right: -250px;
  }
  to {
    right: 0;
  }
}
@keyframes closeSidebar {
  from {
    right: 0;
  }
  to {
    right: -250px;
  }
}

.sidebar {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  background-color: #25293610;
  backdrop-filter: blur(1px);
  &.--open {
    .sidebar-inner {
      animation: openSidebar 0.2s forwards;
    }
  }

  &.--close {
    .sidebar-inner {
      animation: closeSidebar 0.2s forwards;
    }
  }

  .sidebar-inner {
    background-color: $secondary-bg-color;
    box-shadow: -10px 0 15px $shadow-color-xs;
    height: 100vh;
    width: 200px;
    padding: calc($sp-nav-mob + $sp-lg) 0;
    box-sizing: border-box;
    position: absolute;
    right: 0;

    .sidebar-item {
      display: flex;
      align-items: center;
      gap: $sp-md;
      padding: $sp-md;
      cursor: pointer;

      .sidebar-item-icon {
        width: $icon-w-sm;
      }
      .sidebar-item-label {
        font-size: $txt-md;
      }

      &:hover {
        background-color: $secondary-bg-color-hover;
      }
    }
  }
}

// for desktop
@media (min-width: 600px) {
  .navbar {
    padding: $sp-lg;
    height: $sp-nav-des;

    .navbar-inner {
      width: 960px;
      padding: 0 $sp-xs;
      box-sizing: border-box;
      .navbar__brand {
        .navbar__brand-img {
          width: 36px;
        }
      }
      .navbar-menu {
        display: none;
      }

      .navbar-user {
        .navbar-user-dp {
          width: 64px;
          border-radius: 40px;

          &.--long {
            display: block;
          }
          &.--small {
            display: none;
          }
        }
      }
    }
  }

  .sidebar {
    .sidebar-inner {
      padding: calc($sp-nav-des + $sp-lg) 0;
    }
  }
}
@media (min-width: 1000px) {
  .navbar {
    padding: 16px 30px;
    height: $sp-nav-des;
  }
}
</style>
