<template>
  <create-card>
    <template v-slot:header>
      <!-- content for the header slot -->
      <h2 class="card-header">Contact Details</h2>
      <!-- <p class="card-header-company-name">Trek Dieries</p> -->
    </template>

    <p class="section-note">
      Details will be used for all communication channels to stay in touch.
    </p>
    <div class="input_wrap my-20">
      <label for="smInput" class="sm-label">Company email</label>
      <input
        v-model="companyEmail"
        class="--name-input sm_input my-10"
        type="email"
        placeholder="Enter your company email"
        autocomplete="off"
        @keyup="validateEmail"
      />
      <p class="input-note --warning">
        Please enter your correct Email. All notifications will be sent on this
        email.
      </p>
      <p class="input-note --error" v-if="!isEmailValid">
        Please enter correct email address
      </p>
    </div>

    <div class="input_wrap my-20">
      <label for="smInput" class="sm-label">Company phone</label>
      <input
        v-model="companyPhone"
        class="--name-input sm_input my-10"
        type="Number"
        placeholder="Enter your company contact no."
        autocomplete="off"
        @keyup="validateMobileNumber"
      />
      <p class="input-note --warning">
        Please enter your correct WhatsApp no. All notifications will be sent on
        this no.
      </p>
      <p class="input-note --error" v-if="!isMobileValid">
        Please enter correct mobile no.
      </p>
    </div>

    <div class="cta-wrap">
      <div class="pill_btn --hollow --left-icon" @click="back()">
        <img class="back-btn-icon" src="@/assets/icons/angle-left.svg" alt="" />
        Back
      </div>
      <div
        class="pill_btn --right-icon"
        :class="{
          '--disabled':
            loadingUpdate == true ||
            !companyEmail ||
            !companyPhone ||
            !isEmailValid ||
            !isMobileValid,
        }"
        @click="updateContact()"
      >
        Save & Next <img src="@/assets/icons/angle-right.svg" alt="" />
      </div>
    </div>
  </create-card>
</template>

<script>
import CreateCard from "@/components/layouts/CreateCard.vue";
import GetterServices from "@/services/company/GetterServices";
import CompanySetters from "@/services/company/SetterServices";
import computedMixin from "@/mixins/computedMixin";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  mixins: [computedMixin],
  components: { CreateCard },
  data() {
    return {
      loadingUpdate: false,
      companyEmail: null,
      companyPhone: null,
      isEmailValid: true,
      isMobileValid: true,
    };
  },
  computed: {
    companyId() {
      return this.$route.params.companyId;
    },
  },
  mounted() {
    if (this.companyId) {
      this.prefill();
    }
  },
  methods: {
    async prefill() {
      let company = await GetterServices.companyById(this.companyId);
      this.company = company.data;
      if (this.company.company_email) {
        this.companyEmail = this.company.company_email;
      }
      if (this.company.company_phone_no) {
        this.companyPhone = this.company.company_phone_no;
      }
    },
    back() {
      this.$router.push({
        name: "CreateNewCompany",
        query: { companyId: this.company._id },
      });
    },
    async updateContact() {
      try {
        this.loadingUpdate = true;
        const data = {
          companyEmail: this.companyEmail,
          companyPhone: this.companyPhone,
        };
        const company = await CompanySetters.updateCompanySection(
          this.companyId,
          data,
          "contact"
        );
        if (company?._id) {
          this.$router.push({
            name: "CreateProfile",
            params: { companyId: company._id },
          });
        }
      } catch (error) {
        toast.error("Something went wrong, please try again!", {
          autoClose: 3000,
          hideProgressBar: true,
        });
        this.loadingUpdate = false;
      }
    },

    validateEmail() {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

      this.isEmailValid = emailRegex.test(this.companyEmail);
    },
    validateMobileNumber() {
      const mobileNumberRegex = /^[789]\d{9}$/;

      this.isMobileValid = mobileNumberRegex.test(this.companyPhone);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.--name-input {
  font-size: $txt-sm;
  padding: $sp-md;
}

.card-header-company-name {
  margin-bottom: 36px;
}
.card-header,
.card-header-company-name {
  text-align: left;
}
.cta-wrap {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media only screen and (min-width: 600px) {
  .--name-input {
    font-size: $txt-md;
    padding: $sp-md;
  }
}
</style>
