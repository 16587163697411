<template>
  <div class="auth_card">
    <div class="brand_name">
      <img class="brand_logo" src="@/assets/logos/long_logo.svg" alt="" />
    </div>
    <form action="" method="post" @submit.prevent="login">
      <div class="input_wrap">
        <label class="input_label" for="Email">Email</label>
        <input
          id="Email"
          class="auth_input"
          type="email"
          placeholder="Enter your email"
          v-model="email"
        />
        <div class="input-note --error" v-if="emailResetError">
          {{ emailResetError }}
        </div>
      </div>
      <label class="input_label" for="Password">Password</label>
      <input
        id="Password"
        class="auth_input --password"
        type="password"
        placeholder="Enter your password"
        v-model="password"
      />
      <div class="forget_action" @click="resetPassword()">Forget password?</div>
      <br />
      <button type="submit" class="auth_btn" @click="login" v-if="!loading">
        Sign-in
      </button>
    </form>
    <!-- <div class="auth_btn" @click="loginWithLink">Password less</div> -->
    <div
      class="auth_btn --google"
      @click="
        loading = true;
        loginWithGoogle();
      "
      v-if="!loading"
    >
      <div class="google_icon_box">
        <img
          class="google_icon"
          src="@/assets/icons/google-icon.png"
          alt="google sign in logo"
        />
      </div>
      <span>Sign-in with Google</span>
    </div>
    <div class="auth_btn --loader" v-if="loading">
      <LoaderSpinner class="spinner"></LoaderSpinner>
    </div>
    <p>
      Don't have an account?
      <router-link to="/signup">Sign-up</router-link>
    </p>
  </div>
</template>

<script>
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  // sendSignInLinkToEmail,
  // isSignInWithEmailLink,
  // signInWithEmailLink,
} from "firebase/auth";
import LoaderSpinner from "../common/LoaderSpinner.vue";
import UserSetters from "@/services/user/SetterService";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import computedMixin from "@/mixins/computedMixin";
import CompanyGetters from "@/services/company/GetterServices";
export default {
  mixins: [computedMixin],
  name: "RegisterView",
  components: {
    LoaderSpinner,
  },
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      emailResetError: null,
      // actionCodeSettings: {
      //   url: "http://localhost:8080/login",
      //   handleCodeInApp: true,
      // },
    };
  },
  mounted() {
    localStorage.removeItem("currentCompany");
    this.company = null;
  },

  methods: {
    async redirectLogin() {
      try {
        const company = JSON.parse(localStorage.getItem("currentCompany"));
        if (company && company.company_username) {
          this.$router.push({
            name: "CompanyDashboard",
            params: { companyId: company?._id },
          });
        } else {
          const companies = await CompanyGetters.userCompanies();
          if (companies) {
            this.$router.push({
              name: "CompanyDashboard",
              params: { companyId: companies[0]?._id },
            });
          } else {
            this.$router.push({ name: "CreateNewCompany" });
          }
        }
      } catch (error) {
        toast.error("Something went wrong", {
          autoClose: 3000,
          hideProgressBar: true,
        });
        this.$router.push({ name: "UserDashboard" });
      }
    },
    login() {
      this.loading = true;

      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then(async (/*user*/) => {
          // Signed in
          try {
            let user = await UserSetters.authUser();
            if (user && user._id) {
              this.user = user;
              this.redirectLogin();
              this.loading = false;
            }
          } catch (error) {
            toast.error(
              "Sorry for inconvenience!, something went wrong, please try again!",
              {
                autoClose: 3000,
                hideProgressBar: true,
              }
            );
            this.loading = false;
          }
        })
        .catch((error) => {
          // put alert
          toast.error("Please check your email & password", {
            autoClose: 3000,
            hideProgressBar: true,
          });
          this.loading = false;
        });
    },
    loginWithGoogle() {
      this.loading = true;
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then(async (/*user*/) => {
          // Signed in
          // this.authUser();
          try {
            let user = await UserSetters.authUser();
            if (user && user._id) {
              this.user = user;
              this.loading = false;

              this.redirectLogin();
            }
          } catch (error) {
            toast.error(
              "Sorry for inconvenience!, something went wrong, please try again!",
              {
                autoClose: 3000,
                hideProgressBar: true,
              }
            );
            this.loading = false;
          }
        })
        .catch((error) => {
          toast.error(
            "Sorry for inconvenience!, something went wrong, please try again!",
            {
              autoClose: 3000,
              hideProgressBar: true,
            }
          );
          this.loading = false;
        });
    },
    resetPassword() {
      const auth = getAuth();
      if (this.email) {
        sendPasswordResetEmail(auth, this.email)
          .then(() => {
            // Password reset email sent!
            toast.success("Password reset link has been sent to your email!", {
              autoClose: 3000,
              hideProgressBar: true,
            });
          })
          .catch((error) => {
            toast.error("User not found! Try to Sign-Up", {
              autoClose: 3000,
              hideProgressBar: true,
            });
          });
      } else {
        this.emailResetError = "Please enter your email!";
      }
    },
    // sign in with link ________________________________ For letter use
    // loginWithLink() {
    //   const auth = getAuth();
    //   sendSignInLinkToEmail(auth, this.email, this.actionCodeSettings)
    //     .then(() => {
    //       // The link was successfully sent. Inform the user.
    //       // Save the email locally so you don't need to ask the user for it again
    //       // if they open the link on the same device.
    //       window.localStorage.setItem("emailForSignIn", this.email);
    //       console.log("sent");
    //       // ...
    //     })
    //     .catch((error) => {
    //       const errorCode = error.code;
    //       const errorMessage = error.message;
    //       console.log(error);
    //       // ...
    //     });
    // },

    // checkLinkLogin() {
    //   // Confirm the link is a sign-in with email link.
    //   const auth = getAuth();
    //   if (isSignInWithEmailLink(auth, window.location.href)) {
    //     let email = window.localStorage.getItem("emailForSignIn");
    //     if (!email) {
    //       email = window.prompt("Please provide your email for confirmation");
    //     }
    //     // The client SDK will parse the code from the link for you.
    //     signInWithEmailLink(auth, email, window.location.href)
    //       .then((result) => {
    //         console.log(result, " complete");
    //         window.localStorage.removeItem("emailForSignIn");
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.auth_card {
  width: 370px;
  color: $primary-text-color;
  margin-bottom: $sp-xxxl;

  .brand_name {
    display: flex;
    align-items: flex-end;
    margin-bottom: $sp-xxl;
    font-size: 14px;

    .brand_logo {
      padding-top: $sp-sm;
      width: 100px;
    }
  }
}
.auth_input {
  box-sizing: border-box;
  width: 370px;
  padding: $sp-md;
  border-radius: $br-sm;
  display: block;
  background-color: $secondary-bg-color;
  color: $primary-text-color;
  outline: none;
  border: none;
  margin: 0.5em 0 $sp-xs;
  border: 1px solid #ffffff57;

  &:focus {
    border-color: $primary-text-color;
  }
  &.--password {
    margin-bottom: $sp-md;
  }
}
.forget_action {
  width: 370px;
  text-align: right;
  margin-bottom: 0.8em;
  font-size: $txt-sm;
  color: $secondary-text-color;
  cursor: pointer;
}
.auth_btn {
  box-sizing: border-box;
  width: 370px;
  padding: $sp-md;
  background-color: $theme-bg-color;
  color: #fff;
  border-radius: 0.4em;
  font-size: 1.05em;
  margin-bottom: 1em;
  text-align: center;
  border: none;

  &:hover {
    background-color: $theme-bg-color-hover;
    cursor: pointer;
  }

  &.--google {
    background-color: #4285f4;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #2b5dba;
    }

    .google_icon_box {
      float: left;
      margin: 0.1em 5em 0.1em 0.3em;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.2em;
      border-radius: 0.2em;
      position: absolute;
      left: 0.1em;
      .google_icon {
        width: 1.6em;
      }
    }
  }

  &.--loader {
    background-color: transparent;

    .spinner {
      transform: scale(1.3);
    }
  }
}
.anonymous_btn {
  width: 370px;
  border: 1px solid #fff;
  padding: 10px 0;
  border-radius: 10px;
  cursor: pointer;
  color: #fff;
  margin: 50px 0 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #fff;
    color: #252936;
  }

  .anonymous_btn_icon {
    width: 16px;
    margin-left: 10px;
  }
}
@media only screen and (max-width: 450px) {
  .auth_card {
    width: calc(100% - 3em);
    margin: 0 1.5em;
  }
  .auth_input {
    width: 100%;
  }
  .auth_btn {
    width: 100%;
  }
  .forget_action {
    width: 100%;
    opacity: 1;
  }
  .anonymous_btn {
    width: 100%;
  }
}
</style>
