<template>
  <div class="stepbar">
    <div class="cancel-btn" v-if="companyId" @click="goBack()">
      <img
        src="@/assets/icons/arrow-left.svg"
        alt=""
        class="cancel-btn-icon"
      />cancel
    </div>
    <div class="stepbar-inner" :class="{ '--disabled-steps': !companyId }">
      <div
        class="stepbar-item"
        :class="{ '--active': page == 'CreateNewCompany' }"
        @click="changePage('CreateNewCompany')"
      >
        <div class="stepbar-item-point"></div>
        <div class="stepbar-item-name">Name</div>
      </div>
      <div class="sidebar-hr hr hr --dashed"></div>
      <div
        class="stepbar-item"
        :class="{ '--active': page == 'CreateContact' }"
        @click="changePage('CreateContact')"
      >
        <div class="stepbar-item-point"></div>
        <div class="stepbar-item-name">Contact</div>
      </div>
      <!-- <div class="sidebar-hr hr hr --dashed"></div>
      <div
        class="stepbar-item"
        :class="{ '--active': page == 'CreateLocations' }"
        @click="changePage('CreateLocations')"
      >
        <div class="stepbar-item-point"></div>
        <div class="stepbar-item-name">Locations</div>
      </div> -->
      <div class="sidebar-hr hr hr --dashed"></div>
      <div
        class="stepbar-item"
        :class="{ '--active': page == 'CreateProfile' }"
        @click="changePage('CreateProfile')"
      >
        <div class="stepbar-item-point"></div>
        <div class="stepbar-item-name">Profile</div>
      </div>
    </div>
  </div>
</template>

<script>
import computedMixin from "@/mixins/computedMixin";
export default {
  mixins: [computedMixin],
  computed: {
    page() {
      return this.$route.name;
    },
    companyId() {
      return this.$route.params.companyId || this.$route.query.companyId;
    },
  },
  methods: {
    goBack() {
      if (this.companyId) {
        this.$router.push({
          name: "CompanyDashboard",
          params: { companyId: this.company?._id },
          query: { section: "profile" },
        });
      } else {
        this.$router.push({
          name: "UserDashboard",
        });
      }
    },
    changePage(pageName) {
      if (pageName == "CreateNewCompany") {
        this.$router.push({
          name: pageName,
          query: { companyId: this.companyId },
        });
      } else {
        this.$router.push({
          name: pageName,
          params: { companyId: this.companyId },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.stepbar {
  margin: $sp-sm 0 $sp-xxl;

  .cancel-btn {
    display: flex;
    width: fit-content;
    align-items: center;
    gap: $sp-sm;
    padding-bottom: $sp-lg;
    cursor: pointer;

    .cancel-btn-icon {
      width: $icon-w-xs;
    }
  }
  .stepbar-inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    // gap: $sp-xs;

    &.--disabled-steps {
      pointer-events: none;
    }

    .stepbar-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: $sp-xs;
      font-size: $txt-xs;
      color: $secondary-text-color;
      cursor: pointer;

      &.--active {
        color: $primary-text-color;

        .stepbar-item-point {
          border: 2px solid $primary-text-color;
        }
      }
      .stepbar-item-point {
        width: $dis-6;
        height: $dis-6;
        border: 2px solid $tertiary-text-color;
        border-radius: 10px;
      }
    }
    .sidebar-hr {
      margin-top: 5px;
      width: 100%;
    }
  }
}

@media (min-width: 600px) {
  .stepbar {
    margin: $sp-xl 0;
  }
}
</style>
