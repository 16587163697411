import axios from "axios";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import constants from "@/constants";

class BookingSetters {
  static async createBooking(passedData) {
    try {
      return new Promise((resolve, reject) => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            auth.currentUser
              .getIdToken(/* forceRefresh */ true)
              .then((idToken) => {
                const url = `${constants.apiUrl}/booking-setters/create`;
                const config = {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    Authorization: `Bearer ${idToken}`,
                  },
                };

                const data = passedData;

                axios
                  .post(url, data, config)
                  .then((response) => {
                    const data = response.data;
                    unsubscribe();
                    resolve(data);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            reject("user not found");
          }
        });
      });
    } catch (error) {
      return error;
    }
  }
  static async changeStatus(bookingId, bookingStatus) {
    return new Promise((resolve, reject) => {
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          auth.currentUser
            .getIdToken(/* forceRefresh */ true)
            .then((idToken) => {
              const url = `${constants.apiUrl}/booking-setters/update-status`;

              const config = {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json;charset=UTF-8",
                  Authorization: `Bearer ${idToken}`,
                },
              };

              const data = { bookingId, bookingStatus };

              axios
                .put(url, data, config)
                .then((data) => {
                  const response = data.data;
                  unsubscribe();
                  resolve(response);
                })
                .catch((err) => {
                  reject(err);
                });
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    });
  }
}

export default BookingSetters;
