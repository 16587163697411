import { Module } from "vuex";

interface Trek {
  _id: number;
  trek_name: string;
  trek_slug: string;
  company_id: string;
}

interface TrekState {
  trek: Trek | null;
}

const trekModule: Module<TrekState, any> = {
  namespaced: true,
  state: {
    trek: null,
  },
  mutations: {
    setTrek(state, trek) {
      state.trek = trek;
    },
  },
  actions: {
    setTrek({ commit }, trek) {
      commit("setTrek", trek);
    },
  },
  getters: {
    getTrek(state) {
      return state.trek;
    },
  },
};

export default trekModule;
