import { Module } from "vuex";

interface User {
  id: number;
  username: string;
  email: string;
  display_name: string;
}

interface UserState {
  user: User | null;
}

const userModule: Module<UserState, any> = {
  namespaced: true,
  state: {
    user: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit("setUser", user);
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
  },
};

export default userModule;
