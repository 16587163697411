<template>
  <div class="checkin-page">
    <div class="qr-wrap">
      <qrcode-stream
        class="qr-window"
        @detect="onDetect"
        @init="onInit"
        @error="console.log('error')"
      ></qrcode-stream>
      <div class="qr-postioning-layer">
        <div class="qr-pos-inner">
          <div class="close-icon" @click="$emit('close')">
            <img class="close-icon-img" src="@/assets/icons/cross-small.svg" />
          </div>
          <h4 class="qr-pos__heading">Scan E-ticket QR code</h4>
          <div class="qr-pos__scan-box">
            <div class="qr-pos__scan-box-corner --corner-1"></div>
            <div class="qr-pos__scan-box-corner --corner-2"></div>
            <div class="qr-pos__scan-box-corner --corner-3"></div>
            <div class="qr-pos__scan-box-corner --corner-4"></div>
          </div>
          <p class="qr-pos-note">Please position the QR within the scanner</p>
        </div>
      </div>
    </div>
  </div>
  <div class="booking-details">
    <div class="booking-details__inner">
      <div class="loader-booking my-20" v-if="loadingBooking">
        <loader-spinner></loader-spinner>
        <p>Loading Details</p>
      </div>
      <div class="no-booking my-20" v-if="!loadingBooking && !booking">
        <h4 class="text-center">Scan the ticket QR to check-in</h4>
      </div>
      <div class="booking-card" v-if="booking">
        <h3 class="heading-lg">{{ booking?.customer.customer_name }}</h3>

        <h3 class="heading-md my-15">{{ booking?.booking_id }}</h3>
        <h4 class="h6 heading-sm">Status: {{ booking?.booking_status }}</h4>
        <h4 class="h6 heading-sm">
          Payment Status: {{ booking?.payment_status }}
        </h4>
      </div>

      <div class="booking-card" v-if="booking">
        <h3 class="heading-lg">
          <span>Seats: ( {{ booking?.seats.length }} seats )</span>
        </h3>
        <div class="hr"></div>
        <div class="seats-table">
          <div class="seat-row" v-for="seat in booking?.seats" :key="seat._id">
            <p class="seat-name content-sm">
              {{ seat.name }}
            </p>
            <p class="seat-info content-sm">
              {{ seat.age }}
              {{ seat.gender }}
            </p>
          </div>
        </div>
      </div>
      <div class="booking-card" v-if="booking">
        <h3 class="heading-lg">
          Payment
          <!-- <div
            class="pill_btn --small"
            :class="{ '--disabled': loadUpdate }"
            v-if="booking?.booking_status != 'booked'"
            @click="changeStatus(booking._id, 'booked', bi)"
          >
            Mark Paid
          </div>
          <h4 class="payment-status" v-if="booking?.booking_status == 'booked'">
            {{ booking.payment_status }}
          </h4> -->
        </h3>
        <div class="hr"></div>
        <div class="price-table">
          <div class="price-row">
            <p class="content-sm">Price per person</p>
            <p class="content-sm">{{ booking?.price.price_value }}</p>
          </div>
          <div class="price-row">
            <p class="content-sm">Seats</p>
            <p class="content-sm">{{ booking?.seats.length }}</p>
          </div>
          <div class="hr --dashed m-0"></div>
          <div class="price-row">
            <p class="heading-md">Total</p>
            <p class="heading-md">
              {{ booking?.price.price_value * booking?.seats.length }}
            </p>
          </div>
          <div class="price-row">
            <p class="heading-md">Paid</p>
            <p class="heading-md">
              {{ booking?.paid_amount }}
            </p>
          </div>
          <div class="hr --dashed m-0"></div>
          <div class="price-row">
            <p class="heading-md">Remaining</p>
            <p class="heading-md">
              {{
                booking?.price.price_value * booking?.seats.length -
                booking?.paid_amount
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="next-btn pill_btn" v-if="booking" @click="nextCheckin()">
    Next
  </div>
</template>

<script>
import BookingSetters from "@/services/bookings/SetterService";
import { QrcodeStream } from "vue-qrcode-reader";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import LoaderSpinner from "@/components/common/LoaderSpinner.vue";
export default {
  components: { QrcodeStream, LoaderSpinner },

  data() {
    return {
      scanning: false,
      result: "",
      error: "",
      booking: null,
      loadingBooking: false,
    };
  },
  computed: {
    vendorId() {
      return this.$route.params.vendorId;
    },
  },
  methods: {
    onDetect(content) {
      this.result = content;
      this.changeStatus(this.result[0].rawValue, "checked-in", 0);
    },

    async changeStatus(id, status, index) {
      try {
        this.loadingBooking = true;
        this.booking = await BookingSetters.changeStatus(id, status);
        this.loadingBooking = false;
        toast.success(
          `Booking for ${this.booking.customer.customer_name} is Checked-In`,
          {
            autoClose: 3000,
            hideProgressBar: true,
          }
        );
        this.$emit("checkedin", this.booking);
      } catch (error) {
        this.loadingBooking = false;
        toast.error(`No booking found`, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    },
    nextCheckin() {
      this.booking = null;
      this.result = null;
      this.loadingBooking = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.checkin-page {
  margin-top: 0;
  // height: 100vh;
  position: relative;

  .qr-wrap {
    position: relative;
    .qr-window {
      height: 50vh !important;
      width: 100%;
    }
    .qr-postioning-layer {
      z-index: 20;
      width: 100%;
      height: 50vh;
      background-color: transparent;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .qr-pos-inner {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: center;

        .close-icon {
          position: absolute;
          right: 21px;
          top: 30px;
          font-size: 20px;
          font-weight: 400;
          cursor: pointer;
          text-shadow: 0 0 20px #292929;
          color: #ffffff;

          .close-icon-img {
            width: $icon-w-md;
            height: $icon-w-md;
          }
        }

        .qr-pos__heading {
          color: #ffffff;
          font-weight: 500;
          font-size: 14px;
          text-shadow: 0 0 20px #292929;
        }

        .qr-pos__scan-box {
          width: 150px;
          height: 150px;
          position: relative;

          .qr-pos__scan-box-corner {
            position: absolute;
            width: 24px;
            height: 24px;
            border-color: $theme-bg-color;
            border-style: solid;

            &.--corner-1 {
              left: 0;
              top: 0;
              border-width: 2px 0 0 2px;
            }
            &.--corner-2 {
              right: 0;
              top: 0;
              border-width: 2px 2px 0 0;
            }
            &.--corner-3 {
              right: 0;
              bottom: 0;
              border-width: 0 2px 2px 0;
            }
            &.--corner-4 {
              left: 0;
              bottom: 0;
              border-width: 0 0 2px 2px;
            }
          }
        }
        .qr-pos-note {
          font-size: 9px;
          color: #ffffff;
          text-shadow: 0 0 20px #292929;
        }
      }
    }
  }
}

.booking-details {
  padding: $sp-md;
  box-sizing: border-box;
}
.loader-booking {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: $sp-md;
}
.booking-card {
  background-color: $primary-bg-color;
  border-radius: $br-sm;
  padding: $sp-md;
  margin: $sp-md 0;
}
.heading-lg {
  font-size: $txt-lg;
  font-weight: 600;
  margin-bottom: $sp-sm;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.heading-md {
  font-size: $txt-md;
  font-weight: 400;
  margin-bottom: $sp-xs;
}
.heading-sm {
  font-size: $txt-sm;
  font-weight: 600;
  margin-bottom: $sp-xs;
  text-transform: capitalize;
}
.content-sm {
  font-size: $txt-sm;
  font-weight: 400;
  margin-bottom: $sp-xxs;
}
.contact-btns {
  display: flex;
  align-items: center;
  gap: $sp-md;
}

.seats-table {
  display: flex;
  flex-direction: column;
  gap: $sp-md;

  .seat-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.payment-status {
  font-size: $txt-md;
  text-transform: capitalize;
}
.price-table {
  display: flex;
  flex-direction: column;
  gap: $sp-md;

  .price-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.next-btn {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
}
@media (min-width: 600px) {
  .checkin-manual-wrap {
    .checkin-input-wrap {
      width: 450px;
      margin: 0 auto;
    }
  }
}
</style>
