import axios from "axios";
import constants from "@/constants";
import { getAuth, onAuthStateChanged } from "firebase/auth";

class CompanySetters {
  static async createTrek(passedData) {
    return new Promise((resolve, reject) => {
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          auth.currentUser
            .getIdToken(/* forceRefresh */ true)
            .then((idToken) => {
              const url = `${constants.apiUrl}/trek-template-setters/create`;

              const config = {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json;charset=UTF-8",
                  Authorization: `Bearer ${idToken}`,
                },
              };

              const data = passedData;

              axios
                .post(url, data, config)
                .then((data) => {
                  const response = data.data;
                  unsubscribe();
                  resolve(response);
                })
                .catch((err) => {
                  reject(err);
                });
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    });
  }
  static async updateTrekSection(passedData, updateType) {
    return new Promise((resolve, reject) => {
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          auth.currentUser
            .getIdToken(/* forceRefresh */ true)
            .then((idToken) => {
              const url = `${constants.apiUrl}/trek-template-setters/update-${updateType}`;

              const config = {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json;charset=UTF-8",
                  Authorization: `Bearer ${idToken}`,
                },
              };

              const data = passedData;

              axios
                .put(url, data, config)
                .then((data) => {
                  const response = data.data;
                  unsubscribe();
                  resolve(response);
                })
                .catch((err) => {
                  reject(err);
                });
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    });
  }
  static async copyTrek(companyId, trekId) {
    return new Promise((resolve, reject) => {
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          auth.currentUser
            .getIdToken(/* forceRefresh */ true)
            .then((idToken) => {
              const url = `${constants.apiUrl}/trek-template-setters/copy/${trekId}`;

              const config = {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json;charset=UTF-8",
                  Authorization: `Bearer ${idToken}`,
                },
              };

              const data = { companyId };

              axios
                .post(url, data, config)
                .then((data) => {
                  const response = data.data;
                  unsubscribe();
                  resolve(response);
                })
                .catch((err) => {
                  reject(err);
                });
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    });
  }

  static async updateDeleteEvent(passedData) {
    return new Promise((resolve, reject) => {
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          auth.currentUser
            .getIdToken(/* forceRefresh */ true)
            .then((idToken) => {
              const url = `${constants.apiUrl}/trek-template-setters/delete`;

              const config = {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json;charset=UTF-8",
                  Authorization: `Bearer ${idToken}`,
                },
              };

              const data = passedData;

              axios
                .put(url, data, config)
                .then((data) => {
                  const response = data.data;
                  unsubscribe();
                  resolve(response);
                })
                .catch((err) => {
                  reject(err);
                });
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    });
  }
}

export default CompanySetters;
