<template>
  <div
    class="dropdown_box"
    tabindex="0"
    @keydown.down.prevent="handleArrowDown"
    @keydown.up.prevent="handleArrowUp"
    @keydown.enter.prevent="$emit('selected', selectedOptions)"
  >
    <input ref="dropdownBox" class="dropdown_input" type="text" @click.stop />
    <input
      v-model="searchText"
      ref="dropdownBox"
      class="dropdown_input"
      type="text"
      placeholder="Search"
      @keyup="this.searchOption(searchText)"
      @click.stop
      v-if="searchBox"
    />

    <div class="dropdown" :class="{ '--search_box': searchBox }">
      <div
        class="dropdown_item"
        :class="{ '--selected': selectedOptions.includes(option) }"
        v-for="(option, index) in filteredOptions"
        :key="index"
        @click.stop="selectOption(option, index)"
        @keydown.enter.prevent="selectOption(option, index)"
      >
        {{ option.label || option }}
      </div>
      <!-- <div
        class="add_custom"
        v-if="!filteredOptions.length"
        @click.stop="selectOption(searchText)"
      >
        Add this new type
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "DropDown",
  props: ["options", "multiSelect", "selectedOps", "singleSelect", "searchBox"],
  data() {
    return {
      dropdownOptions: [],
      selectedOptions: [],
      searchText: "",
      filteredOptions: [],
    };
  },
  mounted() {
    this.dropdownOptions = this.options;
    this.filteredOptions = this.dropdownOptions;
    this.selectedOptions = this.selectedOps;
    this.$refs.dropdownBox.focus();
  },
  watch: {
    searchText() {
      this.searchOption(this.searchText);
    },
  },
  methods: {
    handleArrowDown() {
      const currentIndex = this.filteredOptions.indexOf(
        this.selectedOptions[0]
      );
      const nextIndex = currentIndex + 1;

      if (nextIndex < this.filteredOptions.length) {
        this.selectedOptions[0] = this.filteredOptions[nextIndex];
      }
    },

    handleArrowUp() {
      const currentIndex = this.filteredOptions.indexOf(
        this.selectedOptions[0]
      );
      const prevIndex = currentIndex - 1;

      if (prevIndex >= 0) {
        this.selectedOptions[0] = this.filteredOptions[prevIndex];
      }
    },
    selectOption(option) {
      if (!this.singleSelect) {
        if (!this.selectedOptions.includes(option)) {
          this.selectedOptions.push(option);
          this.$emit("selected", this.selectedOptions);
        } else {
          let index = this.selectedOptions.indexOf(option);
          this.selectedOptions.splice(index, 1);
          this.$emit("selected", this.selectedOptions);
        }
        if (!this.dropdownOptions.includes(option)) {
          this.dropdownOptions.push(option);
          this.searchText = "";
        }
      } else {
        this.selectedOptions[0] = option;
        this.$emit("selected", this.selectedOptions);
      }
    },
    searchOption(string) {
      const filteredData = this.dropdownOptions.filter((item) => {
        if (item.label) {
          return item.label?.toLowerCase().includes(string.toLowerCase());
        } else {
          return item?.toLowerCase().includes(string.toLowerCase());
        }
      });
      if (string) {
        this.filteredOptions = filteredData;
      } else {
        this.filteredOptions = this.dropdownOptions;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.dropdown_box {
  position: relative;
  box-shadow: 5px 5px 15px $shadow-color-lg;
  z-index: 2;
}
.dropdown_input {
  border-radius: 10px 10px 0 0;
  position: absolute;
}
.dropdown {
  background-color: $secondary-bg-color;
  box-shadow: 0px 10px 20px $shadow-color-lg;
  padding: 0;
  width: 100%;
  position: absolute;
  top: 0px;
  border-radius: 0 0 10px 10px;
  max-height: 30vh;
  overflow-y: auto;

  &.--search_box {
    top: 40px;
  }

  .dropdown_item {
    padding: $sp-md $sp-lg;
    border-bottom: 1px solid $tertiary-text-color;
    font-size: $txt-xs;

    &:hover {
      cursor: pointer;
      background-color: $secondary-bg-color-hover;
    }

    &.--selected {
      background-color: $secondary-bg-color-hover;
    }
  }
  .add_custom {
    text-align: center;
    padding: 10px;
    background-color: #6082f1;

    &:hover {
      opacity: 0.4;
      cursor: pointer;
      background-color: #1a1d25;
    }
  }
}
</style>
