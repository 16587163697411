<template>
  <div class="trek-details">
    <div class="details-inner" v-if="trek">
      <!-- <div class="details__map-wrap">
        <img
          class="details__map"
          src="@/assets/images/dummy-trek/map.png"
          alt=""
        />
      </div> -->

      <div class="details__nav">
        <div class="details__nav-item" @click="activeTab = 1">
          <h4
            class="details__nav-item-text"
            :class="{ '--active': activeTab === 1 }"
          >
            Itinerary
          </h4>
        </div>
        <div class="details__nav-item" @click="activeTab = 2">
          <h4
            class="details__nav-item-text"
            :class="{ '--active': activeTab === 2 }"
          >
            Cost Includes
          </h4>
        </div>
        <div class="details__nav-item" @click="activeTab = 3">
          <h4
            class="details__nav-item-text"
            :class="{ '--active': activeTab === 3 }"
          >
            Instructions
          </h4>
        </div>
      </div>
      <!-- Tabs -->
      <itinerary-tab
        v-if="activeTab == 1"
        :itinerary="trek.itinerary"
      ></itinerary-tab>
      <cost-includes-tab
        v-if="activeTab == 2"
        :includes="trek.price_includes"
        :excludes="trek.price_excludes"
      ></cost-includes-tab>
      <instructions-tab
        v-if="activeTab == 3"
        :instructions="trek.trek_instructions"
        :thingsToCarry="trek.trek_things_to_carry"
      ></instructions-tab>
    </div>
  </div>
</template>

<script>
import CostIncludesTab from "./details-tabs/CostIncludesTab.vue";
import InstructionsTab from "./details-tabs/InstructionsTab.vue";
import ItineraryTab from "./details-tabs/ItineraryTab.vue";
export default {
  components: { ItineraryTab, CostIncludesTab, InstructionsTab },
  data() {
    return {
      activeTab: 1,
    };
  },
  props: ["trek"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.trek-details {
  width: 100%;
  box-sizing: border-box;
  background-color: $secondary-bg-color;
  padding-bottom: 60px;
  .details-inner {
    width: 100%;
    padding: $sp-md;
    box-sizing: border-box;

    .details__map-wrap {
      width: 100%;
      height: fit-content;
      margin-bottom: $sp-xl;
      .details__map {
        width: 100%;
        height: 150px;
        object-fit: cover;
        border-radius: $br-sm;
      }
    }

    .details__nav {
      background-color: $primary-bg-color;
      border-radius: $br-sm;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $sp-md;

      .details__nav-item {
        width: 33.333%;
        padding: 2px;
        cursor: pointer;

        .details__nav-item-text {
          font-size: $txt-xs;
          font-weight: 300;
          text-align: center;
          padding: $sp-md 0;
          border-radius: $sp-xs;

          &:hover {
            background-color: $secondary-bg-color;
            opacity: 0.6;
          }
          &.--active {
            background-color: $secondary-bg-color;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

// for desktop
@media (min-width: 600px) {
  .trek-details {
    width: 100%;
    // height: calc(100vh - 60px);
    overflow-y: auto;
    position: relative;
    z-index: 11;
    .details-inner {
      .details__nav {
        .details__nav-item {
          .details__nav-item-text {
            font-size: $txt-sm;
          }
        }
      }
    }
  }
}
</style>
