<template>
  <div
    class="toggle_wrapper"
    :class="{ '--single-toggle': !preLabel, '--dark': dark }"
  >
    <div
      class="label"
      @click="
        toggle = !toggle;
        toggled();
      "
    >
      {{ preLabel }}
    </div>
    <div
      class="toggle_outer"
      @click="
        toggle = !toggle;
        toggled();
      "
    >
      <div class="toggle_inner" :class="toggle ? '--on' : '--of'"></div>
    </div>
    <div
      class="label"
      :class="{ 'mx-10': !preLabel }"
      @click="
        toggle = !toggle;
        toggled();
      "
    >
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toggle: false,
    };
  },
  props: ["toggleState", "label", "preLabel", "dark"],
  watch: {
    toggleState() {
      this.toggle = this.toggleState;
    },
  },
  mounted() {
    this.toggle = this.toggleState;
  },
  methods: {
    toggled() {
      this.$emit("toggled", this.toggle);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.--dark {
  .toggle_outer {
    background-color: $primary-bg-color;

    .toggle_inner {
      &.--of {
        background-color: $secondary-bg-color;
      }
    }
  }
}

.label {
  font-size: $txt-sm;
}
.toggle_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;

  &.--single-toggle {
    justify-content: start;
  }
}
.toggle_outer {
  width: 35px;
  height: 20px;
  border-radius: 100em;
  background-color: $secondary-bg-color;
  padding: 1px 4px;
  display: flex;
  align-items: center;
  position: relative;

  .toggle_inner {
    width: 16px;
    height: 16px;
    background-color: #6082f1;
    border-radius: 100em;
    position: absolute;
    top: 3px;

    &.--on {
      animation: toggleOn 0.2s 1 forwards;
      background-color: #6082f1;
    }
    &.--of {
      animation: toggleOf 0.2s 1 forwards;
      background-color: $primary-bg-color;
    }
  }
}

@keyframes toggleOn {
  from {
    left: 4px;
  }
  to {
    left: 23px;
  }
}
@keyframes toggleOf {
  from {
    left: 23px;
  }
  to {
    left: 4px;
  }
}
</style>
